import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import '../../../styles/mpProducts.scss';
import { useSessionContext } from 'Context/SessionContext';

interface Merchant {
  id: string;
  merchant_name: string;
  description: string;
  category: string;
  hero_url: string;
  logo_url?: string;
  status?: string;
  featured?: { title: string; mime_type: string; url: string; source: string }[] | null; 
  days_open?: string | null; 
  hours_open?: string | null; 
  hours_close?: string | null;
  merchant_slug: string; 
  city: string;
}

interface Product {
  id: string;
  store_id: string;
  product_id: string;
  product_name: string;
  product_type: string;
  product_slug: string;
  product_description: string;
  unit_price: string;
  media: { media_url: string }[];
}

interface ProductsListProps {
  products: Product[];
  merchant: Merchant; 
  wishlistProducts: string[];
  handleToggleLike: (productId: string) => void;
}

const ProductsList: React.FC<ProductsListProps> = ({ products, wishlistProducts, handleToggleLike, merchant }) => {
  const currency = process.env.REACT_APP_CURRENCY;
  const { session } = useSessionContext();
  const navigate = useNavigate();
  const [showSeeAll, setShowSeeAll] = useState(false);
  const [isGridView, setIsGridView] = useState(false);
  const productContainerRef = useRef<HTMLDivElement>(null);
  const [loadedImages, setLoadedImages] = useState<{ [key: string]: boolean }>({});

  const [categoryView, setCategoryView] = useState<{ [key: string]: boolean }>({});
  const [categoryOverflow, setCategoryOverflow] = useState<{ [key: string]: boolean }>({});

  const toggleView = () => {
    setIsGridView((prevState) => !prevState);
    setShowSeeAll(true);
  };

  const toggleCategoryView = (productType: string) => {
    setCategoryView((prev) => ({
      ...prev,
      [productType]: !prev[productType],
    }));
  };

  const handleImageLoad = (imageKey: string) => {
    setLoadedImages((prevState) => ({
      ...prevState,
      [imageKey]: true,
    }));
  };

  const checkOverflow = () => {
    if (productContainerRef.current) {
      const { scrollWidth, clientWidth } = productContainerRef.current;
      setShowSeeAll(scrollWidth > clientWidth || isGridView);
    }
  };

  const checkCategoryOverflow = () => {
    Object.keys(groupedProducts).forEach((productType) => {
      const container = document.getElementById(`category-${productType}`);
      if (container) {
        const { scrollWidth, clientWidth } = container;
        setCategoryOverflow((prev) => ({
          ...prev,
          [productType]: scrollWidth > clientWidth || categoryView[productType],
        }));
      }
    });
  };
    useEffect(() => {
        checkOverflow();
        checkCategoryOverflow();

        const handleResize = () => {
        checkOverflow();
        checkCategoryOverflow();
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [products, isGridView, categoryView]);

    useEffect(() => {
        const timer = setTimeout(() => {
          checkCategoryOverflow();
        }, 300);
        return () => clearTimeout(timer);
      }, [products]);
      

    const handleHeartClick = (productId: string) => {
        if (!session.isAuthenticated) {
        navigate('/log-in'); 
        } else {
        handleToggleLike(productId); 
        }
    };

  const groupedProducts = products.reduce((acc: { [key: string]: Product[] }, product) => {
    const { product_type } = product;
    if (!acc[product_type]) {
      acc[product_type] = [];
    }
    acc[product_type].push(product);
    return acc;
  }, {});


  return (
    <div className="products-section">
         <div className="products-header">
      <h2>All Products</h2>
      {showSeeAll && (
            <button className="see-all" onClick={toggleView}>
              {isGridView ? 'Back to Slider' : 'See All'}
            </button>
          )}
      </div>
      <div className={isGridView ? 'products-grid' : 'products-slider'} ref={productContainerRef}>
        {products.length > 0 ? (
          products.map((product) => (
            <div key={product.product_id} className="product-card">
                <div className="product-image-wrapper">
              <div className="product-image">
                <Link to={`/merchant/${merchant.merchant_slug}/product/${product.product_slug}`}>
                  <img src={product.media[0]?.media_url} alt={product.product_name} width="169" height="169" className={loadedImages[`product-${product.product_id}`] ? 'loaded' : ''}
                    onLoad={() => handleImageLoad(`product-${product.product_id}`)} />
                </Link>
                <button className="heart-icon" onClick={() => handleHeartClick(product.id)}>
                  {wishlistProducts.includes(product.id) ? <FaHeart color="#832b99" size="22" /> : <FaRegHeart size="22" />}
                </button>
              </div>
              </div>
              <p className="product-name">{product.product_name}
                <Link
                    to={`/merchant/${merchant.merchant_slug}/product/${product.product_slug}`}
                    style={{ color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}
                    title={product.product_name}
                >
                    {/* {product.product_name.length > 20
                    ? `${product.product_name.slice(0, 20)}...`
                    : product.product_name
                    } */}
                </Link>
              </p>
              <p className="product-price">{currency} {parseFloat(product.unit_price).toFixed(2)}</p>
            </div>
          ))
        ) : (
            <div className='fb-note'>
          <p >No products available.</p>
          </div>
        )}
      </div>
     {/* Category-based Product Sections */}
     {Object.entries(groupedProducts).map(([productType, productList]) => (
        <div key={productType} className="c-products-section">
          <div className="products-header">
            <h2>{productType}</h2>
            {categoryOverflow[productType] && (
              <button className="see-all" onClick={() => toggleCategoryView(productType)}>
                {categoryView[productType] ? 'Back to Slider' : 'See All'}
              </button>
            )}
          </div>
          <div
            id={`category-${productType}`}
            className={categoryView[productType] ? 'products-grid' : 'products-slider'}
          >
            {productList.map((product) => (
              <div key={product.product_id} className="product-card">
                <div className="product-image-wrapper">
                  <div className="product-image">
                    <Link to={`/merchant/${merchant.merchant_slug}/product/${product.product_slug}`}>
                      <img
                        src={product.media[0]?.media_url}
                        alt={product.product_name}
                        width="169"
                        height="169"
                        className={loadedImages[`product-${product.product_id}`] ? 'loaded' : ''}
                        onLoad={() => handleImageLoad(`product-${product.product_id}`)}
                      />
                    </Link>
                    <button className="heart-icon" onClick={() => handleHeartClick(product.id)}>
                      {wishlistProducts.includes(product.id) ? (
                        <FaHeart color="#832b99" size="22" />
                      ) : (
                        <FaRegHeart size="22" />
                      )}
                    </button>
                  </div>
                </div>
                <p className="product-name">{product.product_name}
                  <Link
                    to={`/merchant/${merchant.merchant_slug}/product/${product.product_slug}`}
                    style={{ color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}
                    title={product.product_name}
                  >
                    {/* {product.product_name.length > 20
                      ? `${product.product_name.slice(0, 20)}...`
                      : product.product_name} */}
                  </Link>
                </p>
                <p className="product-price">
                  {currency} {parseFloat(product.unit_price).toFixed(2)}
                </p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductsList;
