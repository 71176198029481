import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { IoArrowBack } from "react-icons/io5";
import '../../../styles/Product.scss';
import { FavoritesContext } from '../../../Context/favoritesContext';
import T_addToCart from '../../../Components/modal/T_addToCart';
import { IoClose } from "react-icons/io5";
import { tryFetch } from '../../../Helpers/FetchAPI';
import { getTokenOrDefault } from 'Helpers/Storage';
import PageLoading from 'Pages/layout/PageLoading';
import { useSessionContext } from 'Context/SessionContext';

type Media = {
  id: string;
  media_url: string;
  media_source: string;
};

type Specification = {
  attribute_name: string;
  attribute_value: string;
};

type VariantAttribute = {
  attribute_name: string;
  attribute_options: string;
};

type VariantItem = {
  id: string;
  attribute_options: string;
  attribute_checksum: string;
  stock_count: number;
  unit_price: string;
  media_url: string;
};

type Product = {
  id: string;
  store_id: string;
  block_id: string;
  product_name: string;
  product_type: string;
  product_description: string;
  specifications: Specification[];
  product_slug: string;
  unit_price: string;
  stock_count: number;
  status: string;
  media: Media[];
  variants: {
    attributes: VariantAttribute[];
    items: VariantItem[];
  };
};

type Merchant = {
  id: string;
  store_name: string;
  merchant_slug: string;
  store_logo?: string;
};

type Review = {
  rating: string;
  remarks: string;
  date: string;
  media: { url: string; source: string }[];
};

const ProductPage = () => {
  const { merchant_slug, product_slug } = useParams<{ merchant_slug: string; product_slug: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { session } = useSessionContext();

  const { toggleLike} = useContext(FavoritesContext) || {};
  const [merchantId, setMerchantId] = useState<string | null>(null);
  const [productId, setProductId] = useState<string | null>(null);
  const [isVariantModalOpen, setIsVariantModalOpen] = useState(false);
  const [wishlist, setWishlist] = useState<string[]>([]);

  const [product, setProduct] = useState<Product | null>(null);
  const [otherProducts, setOtherProducts] = useState<Product[]>([]);
  const [merchant, setMerchant] = useState<Merchant | null>(null);
  
  const [reviews, setReviews] = useState<Review[]>([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [loadedImages, setLoadedImages] = useState<{ [key: string]: boolean }>({});
  const [isGrid, setIsGrid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState<string | null>(null);

  const currency = process.env.REACT_APP_CURRENCY;
  
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [location]);

  useEffect(() => {
    const fetchWishlist = async () => {
      if (!session.isAuthenticated) return;
      const token = getTokenOrDefault();
      try {
        const response = await tryFetch({
          url: '/g/wishlist',
          method: 'GET',
          token,
        });

        if (response.is_success && Array.isArray(response.data)) {
          const wishlistIds = response.data.map((item: any) => item.item_id);
          setWishlist(wishlistIds);
        } else {
          console.error('Error fetching wishlist:', response.errors);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchWishlist();
  }, [session.isAuthenticated]);

  useEffect(() => {
    const fetchMerchantId = async () => {
      try {
        const response = await tryFetch({ url: `/merchants`, method: 'GET' });
        if (response.is_success && Array.isArray(response.data)) {
          const foundMerchant = response.data.find((m: Merchant) => m.merchant_slug === merchant_slug);
          if (foundMerchant) {
            setMerchantId(foundMerchant.id);
          } else {
            console.error('Merchant not found');
          }
        }
      } catch (error) {
        console.error('Error fetching merchants:', error);
      }
    };

    fetchMerchantId();
  }, [merchant_slug]);

  useEffect(() => {
    if (!merchantId) return;
    const fetchProductId = async () => {
      try {
        const response = await tryFetch({ url: `/merchants/${merchantId}/products`, method: 'GET' });
        if (response.is_success && Array.isArray(response.data)) {
          const foundProduct = response.data.find((p: Product) => p.product_slug === product_slug);
          if (foundProduct) {
            setProductId(foundProduct.id);
          } else {
            console.error('Product not found');
          }
        }
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProductId();
  }, [merchantId, product_slug]);

  useEffect(() => {
    if (!merchantId || !productId) return;
    const fetchProductData = async () => {
      try {
        const response = await tryFetch({
          url: `/merchants/${merchantId}/products/${productId}`,
          method: 'GET',
        });

        if (response.is_success && typeof response.data === 'object') {
          setProduct(response.data as Product);
        } else {
          console.error('Error fetching product data:', response.errors);
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };

    fetchProductData();
  }, [merchantId, productId]);

  useEffect(() => {
    if (!merchantId) return;
    const fetchMerchantData = async () => {
      try {
        const response = await tryFetch({ url: `/merchants/${merchantId}`, method: 'GET' });
        if (response.is_success && typeof response.data === 'object') {
          setMerchant(response.data as Merchant);
        } else {
          console.error('Error fetching merchant data:', response.errors);
        }
      } catch (error) {
        console.error('Error fetching merchant data:', error);
      }
    };

    fetchMerchantData();
  }, [merchantId]);

  useEffect(() => {
      if (!merchantId || !productId) return;
    const fetchReviews = async () => {
      try {
        const response = await tryFetch({ url: `/merchants/${merchantId}/products/${productId}/reviews`, method: 'GET' });
  
        if (response.is_success && Array.isArray(response.data)) {
          const transformedReviews: Review[] = response.data.map((item: any) => ({
            rating: item.rating,
            remarks: item.remarks,
            date: item.created_at,
            media: item.media,
          }));
          setReviews(transformedReviews);
        } else console.error('Error fetching reviews:', response.errors);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };

    fetchReviews();
  }, [merchantId, productId]);

  useEffect(() => {
    if (!merchantId || !productId) return;
      const fetchOtherProducts = async () => {
        try {
          const response = await tryFetch({
            url: `/merchants/${merchantId}/products`,
            method: 'GET',
          });

          if (response.is_success && Array.isArray(response.data)) {
            const filteredProducts = response.data.filter((p: Product) => p.id !== productId);
        setOtherProducts(filteredProducts);
      } else {
        console.error('Error fetching related products:', response.errors);
      }
    } catch (error) {
      console.error('Error fetching related products:', error);
    }
  };
      fetchOtherProducts();
    }, [merchantId, productId]);


  useEffect(() => {
    if (product && product.media.length > 1) {
      const interval = setInterval(() => {
        setCurrentImageIndex(prevIndex => (prevIndex + 1) % product.media.length);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [product]);

  const handleToggleLike = async () => {
    if (!product) return;
  
    const token = getTokenOrDefault();
    const isInWishlist = wishlist.includes(product.id);
  
    setWishlist((prevWishlist) =>
      isInWishlist
        ? prevWishlist.filter((id) => id !== product.id)
        : [...prevWishlist, product.id]
    );
  
    try {
      if (isInWishlist) {
        const response = await tryFetch({
          url: '/g/wishlist',
          method: 'DELETE',
          token,
          payload: { item_id: product.id },
        });
  
        if (!response.is_success) {
          console.error('Failed to remove from wishlist:', response.error);
          setWishlist((prevWishlist) => [...prevWishlist, product.id]);
        }
      } else {
        const response = await tryFetch({
          url: '/g/wishlist',
          method: 'POST',
          token,
          payload: { item_id: product.id },
        });
  
        if (!response.is_success) {
          console.error('Failed to add to wishlist:', response.error);
          setWishlist((prevWishlist) =>
            prevWishlist.filter((id) => id !== product.id)
          );
        }
      }
    } catch (error) {
      console.error('Error toggling wishlist:', error);
      setWishlist((prevWishlist) =>
        isInWishlist
          ? [...prevWishlist, product.id]
          : prevWishlist.filter((id) => id !== product.id)
      );
    }
  };

  useEffect(() => {
    if (isModalOpen || isVariantModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalOpen, isVariantModalOpen]);

  const toggleDescription = () => setShowFullDescription(!showFullDescription);
  const toggleGrid = () => setIsGrid(!isGrid);

  const handleSwipe = (direction: 'left' | 'right') => {
    if (product) {
      if (direction === 'left') {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % product.media.length);
      } else {
        setCurrentImageIndex(
          (prevIndex) => (prevIndex - 1 + product.media.length) % product.media.length
        );
      }
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('left'),
    onSwipedRight: () => handleSwipe('right'),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const handleBackClick = () => {
    if (product && product.store_id) {
      navigate(-1);
    } else {
      navigate(-1);
    }
  };

  const handleImageLoad = (imageKey: string) => {
    setLoadedImages((prevState) => ({
      ...prevState,
      [imageKey]: true,
    }));
  };

  const handleAddToCartClick = async () => {
    if (!session.isAuthenticated) {
      navigate('/log-in');
    } else {
      await setIsVariantModalOpen(true); 
    }
  };

  const handleCloseVariantModal = () => {
    setIsVariantModalOpen(false);
  };

  const handleConfirmAddToCart = () => {
    setIsVariantModalOpen(false);
  };

  if (!product) {
    return <PageLoading />;
  }

  const handleImageClick = (src: string) => {
    setModalImageSrc(src);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImageSrc(null);
  };

  const descriptionWords = product.product_description.split(" ");
  const isLongDescription = descriptionWords.length > 20;
  const truncatedDescription = descriptionWords.slice(0, 20).join(" ");
  const isProductInWishlist = wishlist.includes(product.id);

  return (
    <div className="product-page">
      <div className="product-banner" {...swipeHandlers}>
        <button className="back-arrow" onClick={handleBackClick} >
                  <IoArrowBack size={30} />
                </button>
      {/* <IoArrowBack
          size={30}
          className="back-arrow"
          onClick={handleBackClick}
        /> */}
         <img
          src={product.media[currentImageIndex]?.media_url}
          alt={`Product ${currentImageIndex}`}
          className={`banner-image ${loadedImages[product.media[currentImageIndex]?.media_url] ? 'loaded' : ''}`}
          onLoad={() => handleImageLoad(product.media[currentImageIndex]?.media_url!)}
        />
        <div className="carousel-indicators">
          {product.media.map((_, index) => (
            <span
              key={index}
              className={`indicator ${index === currentImageIndex ? 'active' : ''}`}
              onClick={() => setCurrentImageIndex(index)}
            ></span>
          ))}
      </div>
      </div>
      <div className="product-content">
      <div className="product-image-container">
        <img src={product.media[0]?.media_url} alt={product.product_name} className="product-image" />
      </div>

      <div className="product-info">
      <div className="product-header">
        <h1 className="product-name">{product.product_name}</h1>
        <button className="heart-icon" onClick={handleToggleLike}>
          {isProductInWishlist ? <FaHeart color="#832b99" size="22" /> : <FaRegHeart size="22" />}
        </button>
        </div>
        <p className="product-price">{currency} {parseFloat(product.unit_price).toFixed(2)}</p>
        {product.specifications.length > 0 && (
  <>
        <h3>Specifications</h3>
        <table className="product-spec-table">
          <tbody>
            {product.specifications.map((spec, index) => (
              <tr key={index}>
                <td className="spec-name">{spec.attribute_name}</td>
                <td className="spec-value">{spec.attribute_value}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </>
)}
        <h3>Description</h3>
        <p className="product-des">
          {showFullDescription || !isLongDescription
            ? product.product_description
            : truncatedDescription}
          {isLongDescription && !showFullDescription && (
            <span className="show-more" onClick={toggleDescription}> {' '}...</span>
          )}
        </p>
        <div className="product-actions">
        <button className="add-to-cart" onClick={handleAddToCartClick}>Add to Cart</button>
        </div>
      </div>
    </div>
    {isVariantModalOpen && product.variants && (
        <T_addToCart
          productData={{
          id: product.id,
          unit_price: product.unit_price,
          stock_count: product.stock_count,
          }}
          productId={product.id}
          productName={product.product_name}
          productMedia={product.media}
          productVariants={product.variants}
          merchant={ merchant || { id: '', store_name: '', store_logo: '' }}
          onClose={handleCloseVariantModal}
          sourceKey={0}
        />
      )}
<div className="reviews-section">
<div className="reviews-header">
  <h2 className="reviews-title">Reviews</h2>
  {reviews.length > 2 && !isGrid && (
      <button onClick={toggleGrid} className="see-all-button">
        See All
      </button>
    )}
    {isGrid && (
      <button onClick={toggleGrid} className="see-all-button">
        Show Less
  </button>
  )}
  </div>
  {reviews.length === 0 ? (
          <p className="no-reviews">No Reviews Yet</p>
        ) : (
          <div className={`reviews-slider ${isGrid ? 'grid-view' : ''}`}>
           {reviews.map((review, index) => (
              <div key={index} className="review-card">
              <div className="review-header">
                <div className="review-stars">{'★'.repeat(Math.round(parseFloat(review.rating)))}</div>
                <p className="review-date">{new Date(review.date).toLocaleDateString()}</p>
              </div>
                <p className="review-message">{review.remarks}</p> 
                <p className="review-user">User_1975</p> 
                {/* <p className="review-user">
                  {review.user.name} {review.user.last_name}
                </p> */}
        {review.media.length > 0 && (
          <div className="review-image-container">
            {review.media.map((media, idx) => (
              <img
                key={idx}
                src={media.url}
                alt={`Review Media ${idx + 1}`}
                className="review-image"
                onClick={() => handleImageClick(media.url)}
              />
            ))}
          </div>
        )}
      </div>
    ))}
  </div>
)}
  {isModalOpen && (
        <div className="modal" onClick={closeModal}>
           <div className="modal-content" onClick={(e) => e.stopPropagation()}>
           <button className="modal-close-button" onClick={closeModal}><IoClose size ="28"/></button>
            <img src={modalImageSrc!} alt="Review" className="modal-image" />
          </div>
        </div>
      )}
</div>

{otherProducts.length > 0 && (
<div className="suggestions-section">
        <h2>You Might Also Like</h2>
        <div className="suggestions-slider">
          {otherProducts.map((relatedProduct) => (
            <div key={relatedProduct.id} className="suggestion-card">
              <Link to={`/merchant/${merchant_slug}/product/${relatedProduct.product_slug}`}>
                <img
                  src={relatedProduct.media[0]?.media_url}
                  alt={relatedProduct.product_name}
                  className={loadedImages[`related-${relatedProduct.block_id}`] ? 'loaded' : ''}
                  onLoad={() => handleImageLoad(`related-${relatedProduct.block_id}`)}
                />
              </Link>
              <p className="suggestion-name">{relatedProduct.product_name}</p>
              <p className="suggestion-price">{currency} {parseFloat(relatedProduct.unit_price).toFixed(2)}</p>
            </div>
          ))}
        </div>
      </div>
      )}
      <div className='disclaimer'>
        <p className='p-disclaimer'>Images are for presentation purposes only. Actual product might differ.</p>
      </div>
    </div>
  );
};

export default ProductPage;