import React from 'react';
import '../../styles/Footer.scss';
import { Link } from 'react-router-dom';

export default function Footer() {

  const appVersion = process.env.REACT_APP_VERSION;
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="footer-content">
        <p className="footer-text">Gottit {appVersion} © {currentYear}. All Rights Reserved.</p>
        <Link to="/terms" className="footer-link">Terms and Conditions</Link>
        <Link to="/support" className="footer-link">Support Centre</Link>
      </div>
    </footer>
  );
}
