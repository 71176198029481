import { Helmet } from "react-helmet";
import seoData from "../../src/Components/Assets/seoData.json"; 

const SEO: React.FC = () => {
  return (
    <Helmet>
      <title>{seoData.title}</title>
      <meta name="description" content={seoData.description} />
      <meta name="keywords" content={seoData.keywords.join(", ")} />
      <meta name="author" content="Gottit" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta property="og:title" content={seoData.title} />
      <meta property="og:description" content={seoData.description} />
      <meta property="og:image" content="https://gottit-media.b-cdn.net/sandbox/1.png" />
      <meta property="og:url" content="https://gottit.io" />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seoData.title} />
      <meta name="twitter:description" content={seoData.description} />
      <meta name="twitter:image" content="https://gottit-media.b-cdn.net/sandbox/1.png" />
    </Helmet>
  );
};

export default SEO;
