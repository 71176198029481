import * as yup from 'yup';


export const SignInSchema = yup.object({
  email_address: yup.string().email("Invalid email").required("Email address is required"),
  password: yup.string().required("Password is required"),
  g_token: yup.string().nullable(),
  location: yup.string().nullable()
});


export const SignUpSchema = yup.object({
  email_address: yup
    .string()
    .email('You must enter a valid email')
    .required('Email address is required'),

  password: yup
    .string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      'Must contain 8 characters, one uppercase, one lowercase, one number, and one special character (@$!%*#?&)'
    )
    .required('Password is required'),

  // confirm_password: yup
  //   .string()
  //   .oneOf([yup.ref('password')], 'Passwords did not match')
  //   .required('Password confirmation is required'),

  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),

  phone_number: yup
    .string()
    .matches(/^\(\d{3}\)\d{3}-\d{4}$/, 'Must be valid Phone number')
    .required('Phone number is required'),

  tnc_accepted: yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
});

