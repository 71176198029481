import React, { useEffect, useState } from 'react'
import { SessionType } from 'Context/SessionContext'
import { getTokenOrDefault } from 'Helpers/Storage';
import { fetchProfile, tryRefresh } from 'Security/actions';

export const useUserSession = () => {
    const[isPageLoading, setIsPageLoading] = useState(false);

    const [session, setSession] = useState<SessionType>({
        isAuthenticated: false,
        user: null,
        // access_token: null, //
        // refresh_token: null //
    });

    const token = getTokenOrDefault();

    useEffect(() => {
        // const token = getTokenOrDefault();
        if(token !== "") {
            checkSession(token);
        }
    },[token]);
        // },[]);

    const checkSession = async (token: string) => {
        const result = await tryRefresh(token);
        if(result.is_success && result.status_code === 200) {
            const profile = await fetchProfile(token);
            if (profile.is_success && profile.status_code === 200) {
                const data: any = profile.data;
                setSession({
                    isAuthenticated: true,
                    user: data,
                    // access_token: null, //
                    // refresh_token: null //
                });
            }
        }else {
            setSession({isAuthenticated: false, user: null});
            // setSession({isAuthenticated: false, user: null, access_token: null, refresh_token: null});
        }
    }

    return { session, setSession, isPageLoading, setIsPageLoading }
}