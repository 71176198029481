import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import { FaChevronDown, FaShareAlt } from 'react-icons/fa';
import { FaFacebookF, FaFacebookMessenger, FaWhatsapp, FaEnvelope, FaLink } from 'react-icons/fa6';
import { RiErrorWarningFill } from 'react-icons/ri';
import { tryFetch } from '../../../Helpers/FetchAPI';
import { useSessionContext } from 'Context/SessionContext';
import { getTokenOrDefault } from 'Helpers/Storage';
import '../../../styles/MerchantPage.scss';
import PageLoading from 'Pages/layout/PageLoading';
import ProductsList from '../hooks/mpProducts';
import Gallery from '../hooks/mpGallery';
import Story from '../hooks/mpStory';
import useShare from '../hooks/useShare';
import { FollowingMerchantContext } from 'Context/FollowingMerchantContext';
import { FaRegSquarePlus, FaCheck } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";

interface Product {
  id: string;
  store_id: string;
  product_id: string;
  product_name: string;
  product_type: string;
  product_description: string;
  product_slug: string;
  unit_price: string;
  media: { media_url: string }[];
}

interface Merchant {
  id: string;
  merchant_name: string;
  description: string;
  category: string;
  hero_url: string;
  logo_url?: string;
  status?: string;
  days_open?: string | null;
  hours_open?: string | null;
  hours_close?: string | null;
  merchant_slug: string;
  city: string;
}

export default function MerchantPage() {
  const { merchant_slug } = useParams<{ merchant_slug: string }>();
  const navigate = useNavigate();
  const { session } = useSessionContext();

  const { 
    showShareModal, 
    openModal, 
    closeModal, 
    shareToFacebook, 
    shareToMessenger, 
    shareToWhatsApp,
    shareToEmail,
    copyToClipboard,
    copySuccess,
    defaultMessage,
  } = useShare();

  const [merchant, setMerchant] = useState<Merchant | null>(null);
  const [customMessage, setCustomMessage] = useState(defaultMessage);
  const [products, setProducts] = useState<Product[]>([]);
  const [wishlistProducts, setWishlistProducts] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState("Products");
  const { toggleFollow, followedMerchants } = useContext(FollowingMerchantContext) || {};
  // const [followedMerchants, setFollowedMerchants] = useState<string[]>([]);
  const isFollowing = merchant && followedMerchants?.some((m) => m.id === merchant.id);
  const [selectedType, setSelectedType] = useState<string>("ALL");
  const [productTypes, setProductTypes] = useState<string[]>(["ALL"]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
        const fetchMerchantData = async () => {
              try {
                const merchantsResponse = await tryFetch({
                  url: `/merchants`, 
                  method: 'GET',
                });
          
                if (!merchantsResponse.is_success || !Array.isArray(merchantsResponse.data)) {
                  console.error("Failed to fetch merchants:", merchantsResponse.error);
                  return;
                }
        
                const merchantData = merchantsResponse.data.find((m: Merchant) => m.merchant_slug === merchant_slug);
        
                if (!merchantData) {
                  console.error("Merchant not found for slug:", merchant_slug);
                  return;
                }
        
                const merchantId = merchantData.id;
        
                  const merchantResponse = await tryFetch({
                    url: `/merchants/${merchantId}`,
                    method: 'GET',
                });
        
                if (!merchantResponse.is_success) {
                  console.error("Failed to fetch merchant details:", merchantResponse.error);
                  return;
                }
        
                setMerchant(merchantResponse.data as Merchant);
        
                  const productResponse = await tryFetch({
                    url: `/merchants/${merchantId}/products`,
                    method: 'GET',
                  });

          if (productResponse.is_success && Array.isArray(productResponse.data)) {
            const transformedProducts = productResponse.data.map((product: any) => ({
              id: product.id,
              store_id: product.store_id,
              product_id: product.product_id,
              product_name: product.product_name,
              product_slug: product.product_slug,
              product_type: product.product_type,
              product_description: product.product_description,
              unit_price: String(product.unit_price),
              media: product.media || [{ media_url: product.media_url }],
            }));
            setProducts(transformedProducts);

            
          } else {
            console.error("Failed to fetch products:", productResponse.error);
          }
        } catch (error) {
          console.error("Error fetching merchant data:", error);
        }
      };

    const fetchWishlistData = async () => {
      if (!session.isAuthenticated) return;
      try {
        const token = getTokenOrDefault();
        const wishlistResponse = await tryFetch({ url: '/g/wishlist', method: 'GET', token });

        if (Array.isArray(wishlistResponse.data) && wishlistResponse.data.every(item => 'item_id' in item)) {
          setWishlistProducts(wishlistResponse.data.map(item => item.item_id));
        } else {
          console.error('Unexpected data format in wishlist response');
        }
      } catch (error) {
        console.error('Error fetching wishlist:', error);
      }
    };

    fetchMerchantData();
    fetchWishlistData();
  }, [merchant_slug, session.isAuthenticated]);

   const handleToggleLike = async (productId: string) => {
      const token = getTokenOrDefault();
    
      if (wishlistProducts.includes(productId)) {
        try {
          const response = await tryFetch({
            url: `/g/wishlist`,
            method: 'DELETE',
            token,
            payload: { item_id: productId },
          });
    
          if (response.is_success) {
            setWishlistProducts((prevState) =>
              prevState.filter((id) => id !== productId)
            );
          } else {
            console.error('Failed to remove from wishlist:', response.error);
          }
        } catch (error) {
          console.error('Error removing from wishlist:', error);
        }
      } else {
  
        try {
          const response = await tryFetch({
            url: `/g/wishlist`,
            method: 'POST',
            token,
            payload: { item_id: productId },
          });
    
          if (response.is_success) {
            setWishlistProducts((prevState) => [...prevState, productId]);
          } else {
            console.error('Failed to add to wishlist:', response.error);
          }
        } catch (error) {
          console.error('Error adding to wishlist:', error);
        }
      }
    };

    const handleTypeChange = (type: string) => {
      setSelectedType(type);
      setIsDropdownOpen(false);
      setActiveTab("Products");
    };

    const filteredProducts = selectedType === "ALL" ? products : products.filter((p) => p.product_type === selectedType);

    const formatDaysOpen = (days: string | null | undefined) => {
      if (!days) return 'N/A';

    const fullDayMap: Record<string, string> = {
      Mon: 'Monday',
      Tue: 'Tuesday',
      Wed: 'Wednesday',
      Thu: 'Thursday',
      Fri: 'Friday',
      Sat: 'Saturday',
      Sun: 'Sunday',
    };

    const dayAbbreviations = Object.keys(fullDayMap);
    const dayArray = days.split(',').map(day => day.trim());

    if (dayArray.length === 0) return 'N/A';

    const expandedDays = dayArray.map(day => ({
        day,
        index: dayAbbreviations.indexOf(day),
    })).sort((a, b) => a.index - b.index);

    const ranges: string[] = [];
    let startIndex = dayAbbreviations.indexOf(dayArray[0]);
    let endIndex = startIndex;

    for (let i = 1; i < dayArray.length; i++) {
      const currentIndex = expandedDays[i].index;

      if (currentIndex === endIndex + 1 || (endIndex === 6 && currentIndex === 0)) {
            endIndex = currentIndex;
        } else {
          ranges.push(startIndex === endIndex
            ? fullDayMap[dayAbbreviations[startIndex]]
            : `${fullDayMap[dayAbbreviations[startIndex]]}-${fullDayMap[dayAbbreviations[endIndex]]}`);
        startIndex = currentIndex;
        endIndex = currentIndex;
    }
}
    ranges.push(startIndex === endIndex
      ? fullDayMap[dayAbbreviations[startIndex]]
      : `${fullDayMap[dayAbbreviations[startIndex]]}-${fullDayMap[dayAbbreviations[endIndex]]}`);

  return ranges.join(', ');
};

  const formatTime = (time: string | null | undefined) => {
    if (!time) return 'N/A';
    const [hour, minute] = time.split(':').map(Number);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12;
    return `${formattedHour}:${minute.toString().padStart(2, '0')} ${ampm}`;
  };

  const handleFollowClick = () => {
    if (merchant && toggleFollow) {
      toggleFollow(merchant);
    }
  };
  
  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCustomMessage(e.target.value);
  };

  if (!merchant) return <PageLoading/>;

  return (
    <div className="mp-merchant-page">
      <div className="mp-merchant-banner" style={{ backgroundImage: `url(${merchant.hero_url})` }}>
        <button className="mp-back-button" onClick={() => navigate('/')}>
          <IoArrowBack size={30} />
        </button>
      </div>

      <div className="mp-merchant-info">
        <div className="mp-merchant-header">
          <img 
            src={merchant.logo_url || "https://gottit-media.b-cdn.net/default-mer-logo.png"} 
            alt="Merchant Logo" 
            className="mp-merchant-profile" 
          />
          <div className="mp-merchant-details">
            <h1 className="mp-merchant-name">{merchant.merchant_name}
            {merchant.status === 'ON_VACATION' && (
          <div className="mp-vacation-warning">
            <RiErrorWarningFill className="mp-v-warning-icon" />
            <span className='mp-vw-text'>Your Favorite Merchant is on vacation. Shipment will be delayed.</span>
          </div>
        )}
            </h1>
            <div className="mp-merchant-location">
              <FaLocationDot className="mp-location-icon" />
              <span>{merchant.city}</span>
            </div>
            <p className="mp-merchant-schedule">
            {formatDaysOpen(merchant.days_open)} <br></br>{formatTime(merchant.hours_open)} - {formatTime(merchant.hours_close)}
            </p>
        <div className="mp-merchant-actions">
          <button onClick={handleFollowClick} className={`mp-follow-button ${isFollowing ? 'following' : ''}`}>
            {isFollowing ? <><FaCheck size="18" className="follow-icon" /> Following</> : <><FaRegSquarePlus size="18" className="follow-icon" /> Follow</>}
          </button>
          <button className="mp-share-button" onClick={openModal}>
          <FaShareAlt /> Share
          </button>
        </div>
      </div>
      </div>
      </div>
      

      <div className="mp-merchant-tabs">
       <div className="mp-tab-dropdown">
       <button 
  className={`mp-tab ${activeTab === "Products" ? "active" : ""}`} 
  onClick={() => {
    setActiveTab("Products"); 
    // setIsDropdownOpen(!isDropdownOpen);
  }}
>
  Products 
  <FaChevronDown className="mp-dropdown-icon" />
</button>
          {isDropdownOpen && (
            <div className="mp-dropdown-menu" onClick={(e) => e.stopPropagation()}>
              {productTypes.map((type) => (
                <button key={type} onClick={( ) => handleTypeChange(type)}>{type}</button>
              ))}
            </div>
          )}
        </div>
       {["Gallery", "Our Story"].map((tab) => (
          <button 
            key={tab} 
            className={`mp-tab ${activeTab === tab ? "active" : ""}`} 
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}

      </div>

      <div className="mp-tab-content">
         {activeTab === "Products" && (
  <>
    <ProductsList 
      products={filteredProducts} 
      merchant={merchant}
      wishlistProducts={wishlistProducts} 
      handleToggleLike={handleToggleLike} 
    />
  </>
)}
        {activeTab === "Gallery" && <Gallery/>}
        {activeTab === "Our Story" && (
        <Story 
          description={merchant.description} 
          ourStory={undefined} 
          ourProducts={undefined} 
          termsAndPolicies={undefined} 
        />
      )}
      </div>
      {showShareModal && (
        <div className="mp-share-modal">
          <div className="mp-share-modal-content">
            <h3>Share This Merchant</h3>
            <div className="mp-share-buttons">
            <button onClick={shareToFacebook} className="mp-share-option facebook">
              <FaFacebookF size={20}/> Share to Facebook
            </button>
            <button onClick={shareToWhatsApp} className="mp-share-option whatsapp">
              <FaWhatsapp size={20}/> Share via WhatsApp
            </button>
            <button onClick={() => shareToEmail(customMessage)} className="mp-share-option email">
                <FaEnvelope size={20} /> Email
              </button>
              <button onClick={copyToClipboard} className="mp-share-option copy-link">
                <FaLink size={20} /> Copy Link
              </button>
              </div>
              {copySuccess && <p style={{ color: 'green' }}>{copySuccess}</p>}
            <button onClick={closeModal} className="mp-close-modal">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
