import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { TryVerifyReset, TryResetPassword } from "Security/actions";
import { useSnackbar } from "notistack";
import ReCAPTCHA from "react-google-recaptcha";
import { getEnv } from "../../Helpers/Env";
import { useLocation } from "Pages/hooks/useLocation";
import "../../styles/ResetPassword.scss";

const ResetPassword: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const siteKey = getEnv("GOOGLE_RECAPTCHA") as string;
  const { coordinates } = useLocation();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        if (!token) {
          console.error("No token provided.");
          throw new Error("Invalid token.");
        }
        const response = await TryVerifyReset(token);

        if (response.is_success) {
          setIsTokenVerified(true);
        } else {
          throw new Error(response.error || "Token verification failed.");
        }
      } catch (error: any) {
        console.error("Token verification error:", error);
        enqueueSnackbar(error.message || "Invalid or expired token.", { variant: "error" });
        navigate("/log-in");
      }
    };

    verifyToken();
  }, [token, enqueueSnackbar, navigate]);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await fetch("/assets/asset.json");
        const assets = await response.json();

        const logo = assets.find((item: any) => item.type === "logo");
        setLogoUrl(logo.image_url);
      } catch (error) {
        console.error("Error fetching logo:", error);
      }
    };

    fetchLogo();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      enqueueSnackbar("Passwords do not match!", { variant: "error" });
      return;
    }

    try {
      const recaptchaToken = await recaptchaRef.current?.executeAsync();
      recaptchaRef.current?.reset();

      if (!recaptchaToken) {
        enqueueSnackbar("reCAPTCHA verification failed. Please try again.", { variant: "error" });
        return;
      }

      const payload = {
        password,
        password_reset_token: token,
        g_token: recaptchaToken,
        location: coordinates || "", 
      };

      const response = await TryResetPassword(payload, token!);

      if (response.is_success) {
        enqueueSnackbar("Password reset successfully!", { variant: "success" });
        navigate("/log-in");
      } else {
        throw new Error(response.error || "Failed to reset password.");
      }
    } catch (error: any) {
      console.error("Error resetting password:", error);
      enqueueSnackbar(error.message || "Failed to reset password. Please try again.", { variant: "error" });
    }
  };

  if (!isTokenVerified) {
    return null;
  }

  return (
    <div className="reset-password">
      {logoUrl && <img src={logoUrl} alt="Logo" className="logo" />}
      <h1>Set Your New Password</h1>
      <p>Please enter your new password below.</p>
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <input
            type={isPasswordVisible ? "password" : "text"}
            placeholder="Enter New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            aria-label="Toggle password visibility"
          >
            {isPasswordVisible ? <IoEyeOff /> : <IoEye />}
          </button>
        </div>
        <div className="input-container">
          <input
            type={isConfirmPasswordVisible ? "text" : "password"}
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button
            type="button"
            onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
            aria-label="Toggle confirm password visibility"
          >
            {isConfirmPasswordVisible ? <IoEye /> : <IoEyeOff />}
          </button>
        </div>
        <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} size="invisible" />
        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
