import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FollowingMerchantContext } from '../../../Context/FollowingMerchantContext';
import '../../../styles/Home.scss';

const FollowedMerchantsSection: React.FC = () => {
  const { followedMerchants } = useContext(FollowingMerchantContext) || {};
  const [recentFollowedContent, setRecentFollowedContent] = useState<any[]>([]);
  const [isGridView, setIsGridView] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState<any>(null);
  const [shouldShowSeeAll, setShouldShowSeeAll] = useState(false);

  useEffect(() => {
    if (followedMerchants) {
      const recentContent = followedMerchants.map((merchant: any) => ({
        merchant,
        content:

  Array.isArray(merchant.featured) && merchant.featured.length > 0
          ? merchant.featured[0]
          : { url: merchant.hero_url, source: "HERO" }, 
    }));

    setRecentFollowedContent(recentContent);
    updateVisibility(recentContent);
  }
}, [followedMerchants]);

  const updateVisibility = (content: any[]) => {
    const isMobile = window.matchMedia('(max-width: 768px)').matches;
    const threshold = isMobile ? 1 : 5;
    setShouldShowSeeAll(content.length > threshold);
  };

  useEffect(() => {
    const handleResize = () => {
      updateVisibility(recentFollowedContent);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [recentFollowedContent]);

  const handleModalOpen = (content: any) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalContent(null);
  };

  const toggleView = () => setIsGridView((prev) => !prev);

  return (
    <div className="followed-merchants">
      <div className="followed-header">
        <h2>Followed Merchants</h2>
        {shouldShowSeeAll && (
          <button className="see-all" onClick={toggleView}>
            {isGridView ? 'Back to Slide' : 'See All'}
          </button>
        )}
      </div>
      {recentFollowedContent.length > 0 ? (
        <>
          <div className={`f-merchant-list ${isGridView ? 'grid-view' : 'scrollable'}`}>
            {recentFollowedContent.map(({ merchant, content }) => (
              <div key={merchant.id} className="f-merchant-item">
                <div className="card-overlay"></div> 
                <div
                  className="featured-content"
                  onClick={() => handleModalOpen(content)}
                  style={{ cursor: 'pointer' }}
                >
                  
                   {content.source === 'CDN' ? (
                    <img src={content.url} alt={content.title || 'Featured Content'} />
                  ) : content.source === 'HERO' ? (
                    <img src={content.url} alt="Merchant Hero" />
                  ) : (
                    <iframe src={content.url} title={content.title || 'Video Content'} frameBorder="0" allowFullScreen></iframe>
                  )}
                </div>
                  {/* {content.source === 'CDN' ? (
                    <img src={content.url} alt={content.title || 'Featured Content'} />
                  ) : (
                    <iframe
                      src={content.url}
                      title={content.title || 'Video Content'}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  )}
                </div> */}

                <div className="f-merchant-profile">
                  <Link to={`/merchant/${merchant.merchant_slug}`} className="f-merchant-link">
                    <img
                      src={ merchant.logo_url || merchant.hero_url}
                      alt={merchant.merchant_name}
                      className="f-merchant-hero"
                    />
                  </Link>
                  <Link to={`/merchant/${merchant.merchant_slug}`} className="f-merchant-link">
                    <p className="f-merchant-name">
                      {merchant.merchant_name.length > 18
                        ? `${merchant.merchant_name.slice(0, 18)}...`
                        : merchant.merchant_name}
                    </p>
                  </Link>
                </div>
              </div>
            ))}
          </div>
          {showModal && modalContent && (
            <div className="f-modal-overlay" onClick={handleModalClose}>
              <div className="f-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="f-close-button" onClick={handleModalClose}>
                  &times;
                </button>
                {modalContent.source === 'CDN' ? (
                  <img src={modalContent.url} alt="Featured Content" />
                ) : modalContent.source === 'HERO' ? (
                  <img src={modalContent.url} alt="Merchant Hero" />
                ) : (
                  <iframe
                    src={modalContent.url}
                    title="Video Content"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <p>Follow your favorite merchants!</p>
      )}
    </div>
  );
};

export default FollowedMerchantsSection;
