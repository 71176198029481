import React, { useState, useEffect } from 'react';
import T_addToCart from 'Components/modal/T_addToCart';
import { MdOutlineAddShoppingCart } from 'react-icons/md';
import { FaHeart } from 'react-icons/fa';
import '../../../styles/Favorites.scss';
import { tryFetch } from '../../../Helpers/FetchAPI';
import { getTokenOrDefault } from '../../../Helpers/Storage';
import PageLoading from 'Pages/layout/PageLoading';
import { Link } from 'react-router-dom';

interface Media {
  id: string;
  media_source: string;
  media_url: string;
}

interface WishlistItem {
  id: string;
  store_id: string;
  store_name: string;
  item_id: string;
  product_name: string;
  product_description: string;
  unit_price: string;
  stock_count: number;
  media: Media[];
  merchant_slug: string;
  product_slug: string;
}

interface ProductDetails {
  id: string;
  store_id: string;
  block_id: string;
  product_name: string;
  product_type: string;
  product_description: string;
  specifications: { attribute_name: string; attribute_value: string }[];
  unit_price: string;
  stock_count: number;
  status: string;
  media: Media[];
  variants: {
    attributes: { attribute_name: string; attribute_options: string }[];
    items: any[];
  };
}

const Favorites: React.FC = () => {
  const [wishlist, setWishlist] = useState<WishlistItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState<ProductDetails | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedStoreName, setSelectedStoreName] = useState<string>('');

  const token = getTokenOrDefault();
  const currency = process.env.REACT_APP_CURRENCY || '$';

  const fetchWishlist = async () => {
    try {
      const response = await tryFetch({
        url: '/g/wishlist',
        method: 'GET',
        token,
      });
      if (response.is_success) {
        setWishlist(response.data as WishlistItem[]);
      } else {
        console.error('Failed to fetch wishlist:', response.errors);
      }
    } catch (error) {
      console.error('Error fetching wishlist:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteWishlistItem = async (itemId: string) => {
    try {
      const response = await tryFetch({
        url: '/g/wishlist',
        method: 'DELETE',
        token,
        payload: { item_id: itemId },
      });
      if (response.is_success) {
        setWishlist(wishlist.filter((item) => item.item_id !== itemId));
      } else {
        console.error('Failed to delete item:', response.errors);
      }
    } catch (error) {
      console.error('Error deleting wishlist item:', error);
    }
  };

  const fetchProductDetails = async (storeId: string, itemId: string) => {
    try {
      const response = await tryFetch({
        url: `/merchants/${storeId}/products/${itemId}`,
        method: 'GET',
        token,
      });
      if (response.is_success) {
        const product = response.data as ProductDetails;
        const storeName = wishlist.find((item) => item.item_id === itemId)?.store_name || '';
        setSelectedProduct(product);
        setSelectedStoreName(storeName);
        setShowModal(true);
      } else {
        console.error('Failed to fetch product details:', response.errors);
      }
    } catch (error) {
      console.error('Error fetching product details:', error);
    }
  };

  const handleAddToCartClick = (item: WishlistItem) => {
    fetchProductDetails(item.store_id, item.item_id);
  };

  useEffect(() => {
    fetchWishlist();
  }, []);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div className="favorites-page">
      <h1>Your Wishlist</h1>
      {wishlist.length === 0 ? (
        <p>Create your wishlist – Love it and Gottit!</p>
      ) : (
        <div className="favorites-grid">
          {wishlist.map((item) => (
            <div className="favorite-item" key={item.id}>
              <div className="image-container">
              <Link to={`/merchant/${item.merchant_slug}/product/${item.product_slug}`}>
              {/* <Link to={`/favorites`}> */}
                <img
                  src={item.media[0]?.media_url || '/default-prod.png'}
                  alt={item.product_name}
                  className="product-image"
                />
                </Link>
                <div className="icon-overlay">
                  <MdOutlineAddShoppingCart
                    className="cart-icon"
                    size={25}
                    onClick={() => handleAddToCartClick(item)}
                  />
                  <button
                    className="heart-icon"
                    onClick={() => handleDeleteWishlistItem(item.item_id)}
                  >
                    <FaHeart color="#832b99" size={22} />
                  </button>
                </div>
              </div>
              <div className="product-info">
              {/* <Link to={`/favorites`}> */}
              <Link
                  to={`/merchant/${item.merchant_slug}/product/${item.product_slug}`}
                  className="product-name-link"
                >
                <p className="product-name">
                  {item.product_name.length > 20
                    ? `${item.product_name.slice(0, 20)}...`
                    : item.product_name}
                </p>
                </Link>
                <p className="product-price">
                  {currency} {parseFloat(item.unit_price).toFixed(2)}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
      {showModal && selectedProduct && (
        <T_addToCart
          productData={{
            id: selectedProduct.id,
            unit_price: selectedProduct.unit_price,
            stock_count: selectedProduct.stock_count,
          }}
          productId={selectedProduct.id}
          productName={selectedProduct.product_name}
          productMedia={selectedProduct.media}
          productVariants={selectedProduct.variants ?? { attributes: [], items: [] }}
          merchant={{
            id: selectedProduct.store_id,
            store_name: selectedStoreName,
          }}
          onClose={() => setShowModal(false)}
          sourceKey={1}
          onDeleteFromWishlist={handleDeleteWishlistItem}
        />
      )}
    </div>
  );
};

export default Favorites;