import React, { useContext } from 'react';
import '../../../styles/Home.scss'
import BannerSection from './bannerSection';
import FavoritesSection from './favoritesSection';
import FollowedMerchantsSection from './followedSection';
import MerchantSection from './merchantSection';
import { SessionContext } from 'Context/SessionContext';
import SEO from 'Components/seo';

const Home: React.FC = () => {
  const { session } = useContext(SessionContext);
  const { isAuthenticated } = session;

  return (
    <div className="home">
       <SEO />
      <BannerSection />
      {isAuthenticated && <FavoritesSection />}
      {isAuthenticated && <FollowedMerchantsSection />}
      <MerchantSection />
    </div>
  );
};

export default Home;
