import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../styles/ThankYouPage.scss";
import PageLoading from "Pages/layout/PageLoading";

const ThankYouPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
          setIsLoading(false);
        }, 1000);
    
        return () => clearTimeout(timer);
      }, []);
    
      if (isLoading) {
        return <PageLoading />;
      }

  return (
    <div className="thank-you-container">
      {/* <img
        src="https://gottit-media.b-cdn.net/s/GOTTIT-LOGO.PNG"
        alt="Logo"
        className="ty-logo"
      /> */}
      <h1 className="ty-title">Thank You for Your Purchase!</h1>
      <p className="ty-message">
        Please present your Secure Delivery Code to your rider upon arrival to complete the order. </p>
        <p className="ty-message"> You can find this code on the Order and Delivery Details and on the 'Your Orders' page (please see below). <br />  It will also be sent to you via Viber, SMS, and your Gottit email.</p>
      <div className="ty-instruction">
        {/* <p className="ty-instruction-text">Just like below:</p> */}
        <img
          src="https://gottit-media.b-cdn.net/sandbox/instruction.gif"
          alt="Instruction GIF"
          className="ty-gif"
        />
      </div>
      <Link to="/orders" className="ty-button">
        Go to Order Page
      </Link>
    </div>
  );
};

export default ThankYouPage;
