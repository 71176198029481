import NotFound from 'Pages/layout/NotFound';
import React from 'react';
import { useLocation, Route, Routes } from 'react-router-dom';

const NotFoundRoute = () => {
  const location = useLocation();

  const isRoute = [
    '/', 
    '/log-in',
    '/create-account',
    '/forgot-password',
    '/verify',
    '/verify/:token',
    '/reset-password/:token',
    '/profile',
    '/your-orders',
    '/cart',
    '/check-out',
    '/favorites',
    '/track/:orderId',
    '/review/:orderId/:productId',
    '/merchant/:id',
    '/merchant/:id/product/:spId',
    '/faqs',
    '/support',
    '/terms-and-conditions',
    '/end-user-license-agreement',
    '/return-refund-policy',
    '/privacy-policy',
    '/others',
    '/orders/:orderId/:refId',
    '/orders',
    '/search',
    '/thank-you-for-your-order'
  ].some((path) => location.pathname.match(new RegExp(`^${path.replace(/:[^/]+/g, '[^/]+')}$`)));

  return !isRoute ? (
    <Routes>
      <Route path="*" element={<NotFound />} />
    </Routes>
  ) : null;
};

export default NotFoundRoute;
