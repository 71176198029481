import React, { createContext, useState, useEffect, ReactNode, useContext } from 'react';
import { tryFetch } from '../Helpers/FetchAPI';
import { getTokenOrDefault } from 'Helpers/Storage';
import { SessionContext } from './SessionContext';

interface FavoritesContextType {
  likedProducts: { [key: string]: boolean };
  toggleLike: (productId: string) => void;
  favCount: number;
  fetchWishlist: () => void;
  saveWishlist: (productId: string, isLiked: boolean) => void;
  deleteFromWishlist: (productId: string) => Promise<void>;
}

export const FavoritesContext = createContext<FavoritesContextType | undefined>(undefined);

const FavoritesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [likedProducts, setLikedProducts] = useState<{ [key: string]: boolean }>({});
  // const [favCount, setFavCount] = useState(0);
  const { setFavCount } = useContext(SessionContext);
  const [favCount, setLocalFavCount] = useState(0);
  const {session} = useContext(SessionContext);
  const isAuthenticated = session.isAuthenticated;
  

  const fetchWishlist = async () => {

    if (!isAuthenticated) {
      return;
    }
    try {
      const token = getTokenOrDefault();
      const response = await tryFetch({
        url: '/g/wishlist',
        method: 'GET',
        token,
      });
      
      if (response.is_success && response.data) {
        const fetchedLikedProducts = response.data as { [key: string]: boolean };
        setLikedProducts(fetchedLikedProducts);
        setFavCount(Object.values(fetchedLikedProducts).filter(Boolean).length);
        // const newFavCount = Object.values(fetchedLikedProducts).filter(Boolean).length;
        // setLocalFavCount(newFavCount);
        // setFavCount(newFavCount);
      }
    } catch (error) {
      console.error('Error fetching wishlist:', error);
    }
  };

  const saveWishlist = async (productId: string, isLiked: boolean) => {
    const token = getTokenOrDefault();
    try {
      const response = await tryFetch({
        url: '/g/wishlist',
        method: 'POST',
        payload: { item_id: productId},
        token,
      });
      if (response.is_success) {
        setLikedProducts((prev) => ({
          ...prev,
          [productId]: isLiked,
        }));
        setFavCount(Object.values(likedProducts).filter(Boolean).length);
        // const newFavCount = Object.values(likedProducts).filter(Boolean).length;
        // setLocalFavCount(newFavCount);
        // setFavCount(newFavCount); 
      }
    } catch (error) {
      console.error('Error saving wishlist:', error);
    }
  };

  const deleteFromWishlist = async (productId: string) => {
    const token = getTokenOrDefault();
    try {
      const response = await tryFetch({
        url: '/g/wishlist',
        method: 'DELETE',
        payload: { item_id: productId },
        token,
      });

      if (response.is_success) {
        setLikedProducts((prev) => {
          const updated = { ...prev };
          delete updated[productId];
          return updated;
        });
        setFavCount(favCount - 1);
        // setFavCount((prevCount: number) => prevCount - 1);
        // const newFavCount = Object.values(likedProducts).filter(Boolean).length;
        // setLocalFavCount(newFavCount);
        // setFavCount(newFavCount);
      } else {
        console.error('Failed to delete item from wishlist:', response);
      }
    } catch (error) {
      console.error('Error deleting wishlist item:', error);
    }
  };

  const toggleLike = (productId: string) => {
    const isLiked = !likedProducts[productId];
    if (isLiked) {
      saveWishlist(productId, isLiked);
    } else {
      deleteFromWishlist(productId);
    }
  };

  useEffect(() => {
    fetchWishlist();
  }, []);

  return (
    <FavoritesContext.Provider value={{ likedProducts, toggleLike, favCount, fetchWishlist, saveWishlist, deleteFromWishlist, }}>
      {children}
    </FavoritesContext.Provider>
  );
};

export default FavoritesProvider;
