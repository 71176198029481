import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import '../../styles/SignUp.scss';
import { Link} from 'react-router-dom';
import logo from '../../Components/Assets/logo.png';
import { useSignUp } from './hooks/useSignUp';
import ReCAPTCHA from 'react-google-recaptcha';
import { getEnv } from '../../Helpers/Env';
import { IoEye, IoEyeOff } from "react-icons/io5";
import bniLogo from '../../Components/Assets/bni-logo.png';

const SignUp: React.FC = () => {
  const siteKey = getEnv("GOOGLE_RECAPTCHA") as string;
  const { initialValues, validationSchema, handleSubmit, recaptcha, isSubmitting, errorMessage } = useSignUp();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="signup-container">
      <div className="signup-card">
        <Link to="/">
          <img src="https://gottit-media.b-cdn.net/s/GOTTIT-LOGO.PNG" alt="Logo" className="signup-logo" />
        </Link>
        <h2 className="signup-title">PERSONAL DETAILS</h2>
        <p className="signup-text"> Use Google Chrome for seamless expereience.</p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ handleChange, values }) => (
            <Form noValidate autoComplete="off" className="signup-form">
              <Field
                type="text"
                name="email_address"
                placeholder="EMAIL"
                className="signup-input"
              />
              <ErrorMessage name="email_address" component="div" className="ca-error-message" />

              <div className="sign-up-password-container">
              <Field
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="PASSWORD"
                className="signup-input"
              />
              <button
                  type="button"
                  className="sign-up-eye-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <IoEye /> : <IoEyeOff />}
                </button>
              </div>
              <ErrorMessage name="password" component="div" className="ca-error-message" />

              <Field
                type="text"
                name="first_name"
                placeholder="FIRST NAME"
                className="signup-input"
              />
              <ErrorMessage name="first_name" component="div" className="ca-error-message" />

              <Field
                type="text"
                name="last_name"
                placeholder="LAST NAME"
                className="signup-input"
              />
              <ErrorMessage name="last_name" component="div" className="ca-error-message" />
              <div className="signup-phone-container">
                <Field type="text" name="phone_country_code" value="+63" className="signup-input phone-code" disabled />
                <Field 
                  type="text"
                  name="phone_number"
                  placeholder="PHONE NUMBER"
                  className="signup-input phone-number"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    let value = e.target.value.replace(/\D/g, '').slice(0, 10); // Remove non-numeric and limit to 10 digits
                
                    if (value.startsWith("0")) {
                      value = value.substring(1);
                    }
                
                    let formatted = value;
                    if (value.length >= 7) {
                      formatted = `(${value.slice(0, 3)})${value.slice(3, 6)}-${value.slice(6)}`;
                    } else if (value.length >= 4) {
                      formatted = `(${value.slice(0, 3)})${value.slice(3)}`;
                    }
                
                    handleChange({ target: { name: 'phone_number', value: formatted } });
                  }}
                  value={values.phone_number}
                />
              </div>
              <ErrorMessage name="phone_number" component="div" className="ca-error-message" />


              <div className="ca-terms-container">
                <Field
                  type="checkbox"
                  name="tnc_accepted"
                  className="ca-terms-checkbox"
                />
                {/* <label htmlFor="tnc_accepted" className="ca-terms">
                  I HAVE UNDERSTOOD THE{' '}
                  <Link to="/terms-and-conditions" className="ca-terms">
                    TERMS AND CONDITIONS
                  </Link>.
                </label> */}
                 <label htmlFor="tnc_accepted" className="ca-terms">
                  I have understood the {' '}
                  <Link to="/terms-and-conditions" className="ca-terms">
                    Terms and Conditions
                  </Link>.
                </label>
              </div>
              <ErrorMessage name="tnc_accepted" component="div" className="ca-error-message" />
             
              <div className="ca-terms-container">
                <Field type="checkbox" name="is_bni_member" className="ca-terms-checkbox" />
                {/* <label htmlFor="bni_member" className="ca-terms">
                  I'M A BNI MEMBER
                </label> */}
                <label htmlFor="is_bni_member" className="ca-terms">
                  I'm a <b>BNI</b> member.
                </label>
                {/* <label htmlFor="bni_member" className="ca-terms bni-label">
                I'm a 
                <img src={bniLogo} alt="BNI Logo" className="bni-logo" />
                member.
              </label> */}
              </div>
              <div className="bni-info">
                Not yet a member?{' '}
                <a href="https://bni.ph/en-PH/index" target="_blank" rel="noopener noreferrer">
                  Click here
                </a>.
              </div>

              {errorMessage && <div className="ca-error-message">{errorMessage}</div>}

              <ReCAPTCHA
                ref={recaptcha}
                sitekey={siteKey}
                size="invisible"
              />

              <button type="submit" className="signup-button" disabled={isSubmitting}>
                CREATE ACCOUNT
              </button>
            </Form>
          )}
        </Formik>
        <a href="/log-in" className="su-back">
          Back To Login
        </a>
      </div>
    </div>
  );
};

export default SignUp;