import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContent from 'Routes';
import { SnackbarProvider } from 'notistack'; 
import SEO from 'Components/seo';

function App() {
	return (
		<div className="App">
			< SEO />
			
			<Router>
				<SnackbarProvider maxSnack={3}>
					<AppContent />
				</SnackbarProvider>
			</Router>
			
		</div>
	);
}

export default App;