import React, { useState, useEffect } from 'react';
import { FaEdit } from 'react-icons/fa';
import { MdDeleteForever } from "react-icons/md";
import '../../styles/T_contactList.scss';
import AddContactModal from './T_addContact';
import { tryFetch } from 'Helpers/FetchAPI';
import { getTokenOrDefault } from 'Helpers/Storage';

interface AddressInfo {
  longitude: string;
  latitude: string;
  street_address_1: string;
  street_address_2: string;
  state_province: string;
  town_city: string;
  zip_code: string;
  country: string;
  landmark?: string;
  region: string;
}

interface ContactInfo {
  contact_id: string;
  label: string;
  contact_name: string;
  contact_phone: string;
  contact_email_address: string;
  is_default_address: boolean;
  delivery_instructions?: string;
  address_info: AddressInfo;
}

interface ContactListModalProps {
  contacts?: ContactInfo[];
  onClose: () => void;
  onSave: (selectedContact: ContactInfo) => void; 
}

const ContactListModal: React.FC<ContactListModalProps> = ({ onClose, onSave }) => {
  const [contacts, setContacts] = useState<ContactInfo[]>([]);
  const [selectedContact, setSelectedContact] = useState<ContactInfo | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<string | null>(null);

  // useEffect(() => {

    const token = getTokenOrDefault();
    const fetchContacts = async () => {
      try {
        const response = await tryFetch({
          url: '/g/address',
          method: 'GET',
          token
        });
        
          if (response.is_success && Array.isArray(response.data)) {
            const formattedContacts = response.data.map((item) => ({
            contact_id: item.contact_id,
            label: item.label,
            contact_name: item.contact_name,
            contact_phone: item.contact_phone,
            contact_email_address: item.contact_email_address,
            is_default_address: item.is_default_address,
            delivery_instructions: item.delivery_instructions,
            address_info: {
              longitude: item.address_info.longitude,
              latitude: item.address_info.latitude,
              street_address_1: item.address_info?.street_address_1 || 'N/A',
              street_address_2: item.address_info?.street_address_2 || '',
              state_province: item.address_info.state_province,
              town_city: item.address_info.town_city || '',
              zip_code: item.address_info.zip_code,
              country: item.address_info.country,
              landmark: item.address_info.landmark,
              region:item.address_info.region
            },
          }));
          const defaultContact = formattedContacts.find(c => c.is_default_address);
          if (defaultContact) {
            setSelectedContactId(defaultContact.contact_id);
          }
          setContacts(formattedContacts);
        } else {
          console.error('Error fetching contacts:', response.errors);
        }
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    useEffect(() => {
    fetchContacts();
  }, []);

  const handleEditDefaultAddress = async (contact: ContactInfo) => {
    const token = getTokenOrDefault();

    const payload = {
      contact_id: contact.contact_id,
      label: contact.label,
      contact_name: contact.contact_name,
      contact_phone: contact.contact_phone,
      contact_email_address: contact.contact_email_address,
      is_default_address: true,
      delivery_instructions: contact.delivery_instructions || '',
      address_info: {
        latitude: contact.address_info.latitude,
        longitude: contact.address_info.longitude,
        street_address_1: contact.address_info.street_address_1,
        street_address_2: contact.address_info.street_address_2,
        state_province: contact.address_info.state_province,
        town_city: contact.address_info.town_city,
        zip_code: contact.address_info.zip_code,
        region: contact.address_info.region,
        country: contact.address_info.country,
        landmark: contact.address_info.landmark || '',
      },
    };

    try {
      const response = await tryFetch({
        url: '/g/address',
        method: 'POST',
        token,
        payload,
      });

      if (response.is_success) {
        fetchContacts();
        setContacts((prevContacts) =>
          prevContacts.map((c) =>
            c.contact_id === contact.contact_id
              ? { ...c, ...payload, is_default_address: true }
              : { ...c, is_default_address: false }
          )
        );
        
      } else {
        console.error('Error updating address:', response.errors);
      }
    } catch (error) {
      console.error('Error updating address:', error);
    }
  };

  
  const handleEditClick = (contact: ContactInfo) => {
    setSelectedContact(contact);
    setIsEditModalOpen(true);
  };

  const handleDeleteClick = async (contactId: string) => {
    const token = getTokenOrDefault();
    try {
      const response = await tryFetch({
        url: '/g/address',
        method: 'DELETE',
        token,
        payload: { contact_id: contactId }
      });

      if (response.is_success) {
        fetchContacts();
        setContacts((prevContacts) => prevContacts.filter(contact => contact.contact_id !== contactId));
      } else {
        console.error('Error deleting contact:', response.errors);
      }
    } catch (error) {
      console.error('Error deleting contact:', error);
    }
  };

  const closeEditModal = () => setIsEditModalOpen(false);

  const toggleAddModal = () => setIsAddModalOpen(!isAddModalOpen);

  const handleContactSelect = (contactId: string) => setSelectedContactId(contactId);

  const handleSetDefault = async (contactId: string) => {
    const selectedContact = contacts.find((contact) => contact.contact_id === contactId);
    if (selectedContact) {
      await handleEditDefaultAddress(selectedContact);
    }
  };

  const handleSave = () => {
    const selectedContact = contacts.find((contact) => contact.contact_id === selectedContactId);
    if (selectedContact) {
      onSave(selectedContact);
    }
    onClose();
  };

  const handleAddContact = (newContact: ContactInfo) => {
    setContacts((prevContacts) => [...prevContacts, newContact]);
  };

  return (
    <div className="cl-modal-overlay" onClick={onClose}>
      <div className="cl-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="cl-header-container">
          <h2 className="cl-modal-header">Select Contact List</h2>
          <button className="cl-add-address-button" onClick={toggleAddModal}>
            + Add Address
          </button>
        </div>
        {contacts.map((contact) => (
          <div key={contact.contact_id} className="cl-contact-item">
            <input
              type="radio"
              checked={selectedContactId === contact.contact_id}
              onChange={() => handleContactSelect(contact.contact_id)}
              className="cl-contact-radio"
            />
            <div className="cl-contact-details">
              <p className="cl-p"><strong>{contact.contact_name}</strong></p>
              <p className="cl-p">{contact.contact_phone}</p>
              <p className="cl-p">{contact.label}</p>
              <p className="cl-p">
              {contact.address_info?.street_address_1} ,  {contact.address_info?.street_address_2 || ""}
              </p>
              <p className="cl-p">
              {contact.address_info?.town_city || ""}, {contact.address_info?.state_province || ""}, {contact.address_info?.region || ""}, {contact.address_info?.zip_code || ""}
              </p>
              <div className="cl-default-toggle">
                <label className="cl-label">
                  <input
                    className="cl-input"
                    type="checkbox"
                    checked={contact.is_default_address}
                    onChange={() => handleSetDefault(contact.contact_id)}
                  />
                  <span className="cl-span">Set as Default</span>
                </label>
              </div>
            </div>
            <MdDeleteForever className="cl-delete-icon" onClick={() => handleDeleteClick(contact.contact_id)} />
            <FaEdit className="cl-edit-icon" onClick={() => handleEditClick(contact)} />
          </div>
        ))}
        {isEditModalOpen && <AddContactModal onClose={closeEditModal} onSave={handleSave} contact={selectedContact|| null}  />}
        {isAddModalOpen && <AddContactModal onClose={toggleAddModal} onSave={handleAddContact}/>}
        <button className="cl-modal-save-button" onClick={handleSave}>
          Save
        </button>
        <button className="cl-modal-close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ContactListModal;
