import React, { useState, useEffect } from "react";
import '../../styles/T_addContact.scss';
import DragableMap from "../map/dragableMap";
import { tryFetch } from "Helpers/FetchAPI";
import { getTokenOrDefault } from 'Helpers/Storage';
import TPhoneMaskAdapter from "Components/TPhoneMaskAdapter";
import { Input, Button, Typography, Stack, Select, Option } from "@mui/joy";
import PageLoading from "Pages/layout/PageLoading";

interface AddressInfo {
    latitude: string;
    longitude: string;
    street_address_1: string;
    street_address_2: string;
    state_province: string;
    town_city: string;
    zip_code: string;
    country: string;
    landmark?: string;
    region: string;
  }
  
  interface ContactInfo {
    contact_id: string;
    label: string;
    contact_name: string;
    contact_phone: string;
    contact_email_address: string;
    is_default_address: boolean;
    delivery_instructions?: string;
    address_info: AddressInfo
  }

interface AddContactModalProps {
  contact?: ContactInfo | null;
  onClose: () => void;
  onSave: (updatedContact: ContactInfo) => void;
}

const regionOptions = [
  { 
    label: "NCR", 
    value: "NCR" 
  },
  { 
    label: "Ilocos Region", 
    value: "Ilocos Region" 
  },
  { 
    label: "Cagayan Valley", 
    value: "Cagayan Valley" 
  },
  { 
    label: "Central Luzon", 
    value: "Central Luzon" 
  },
  { 
    label: "CALABARZON", 
    value: "CALABARZON" 
  },
  { 
    label: "Bicol Region", 
    value: "Bicol Region" 
  },
  { 
    label: "CAR", 
    value: "CAR" 
  }
];

const AddContactModal: React.FC<AddContactModalProps> = ({ contact, onClose, onSave }) => {
    const [formData, setFormData] = useState({
        label: "",
        contactName: "",
        contactNumber: "",
        contactEmail: "",
        defaultAddress: false,
        delInstructions: "",
        latitude: "",
        longitude: "",
        streetAddress1: "",
        streetAddress2: "",
        city: "",
        province: "",
        zipCode: "",
        country: "PH",
        landmark: "",
        region: "",
    });

    const [isLocationPinned, setIsLocationPinned] = useState(false);

    useEffect(() => {
        if (contact) {
          setFormData({
            label: contact.label,
            contactName: contact.contact_name,
            contactNumber: contact.contact_phone,
            contactEmail: contact.contact_email_address,
            defaultAddress: contact.is_default_address,
            delInstructions: contact.delivery_instructions || "",
            latitude: contact.address_info?.latitude || "",
            longitude: contact.address_info?.longitude || "",
            streetAddress1: contact.address_info?.street_address_1 || "",
            streetAddress2: contact.address_info?.street_address_2 || "",
            city: contact.address_info?.town_city || "",
            province: contact.address_info?.state_province || "",
            zipCode: contact.address_info?.zip_code || "",
            country: "PH",
            landmark: contact.address_info?.landmark || "",
            region: contact.address_info?.region || "" ,
          });
          setIsLocationPinned(!!contact.address_info?.latitude && !!contact.address_info?.longitude);
        }
      }, [contact]);

      const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name] : value,
        }));
    };

    const handleRegionChange = (event: React.SyntheticEvent | null, newValue: string | null) => {
      setFormData((prevData) => ({
          ...prevData,
          region: newValue || ""
      }));
  };
    
      const handleLocationChange = (details: {
        lat: string;
        lng: string;
        city: string;
        province: string;
        region: string;
        streetAddress1: string;
        streetAddress2: string;
      }) => {
        setFormData((prevData) => ({
            ...prevData,
            streetAddress1: details.streetAddress1,
            streetAddress2: details.streetAddress2,
            city: details.city,
            province: details.province,
            region: details.region,
            latitude: details.lat,
            longitude: details.lng
          }));
        };

        const [errors, setErrors] = useState<Record<string, string>>({});
        const validateForm = () => {
          let newErrors: Record<string, string> = {};
        
          if (!formData.label.trim()) newErrors.label = "Label is required";
          if (!formData.contactName.trim()) newErrors.contactName = "Name is required";
          if (!formData.contactNumber.trim()) newErrors.contactNumber = "Contact number is required";
          if (!formData.contactEmail.trim()) newErrors.contactEmail = "Email is required";
          if (!formData.streetAddress1.trim()) newErrors.streetAddress1 = "Street Address 1 is required";
          if (!formData.streetAddress2.trim()) newErrors.streetAddress2 = "Street Address 2 is required";
          if (!formData.city.trim()) newErrors.city = "City is required";
          if (!formData.province.trim()) newErrors.province = "Province is required";
          if (!formData.zipCode.trim()) newErrors.zipCode = "Zip Code is required";
          if (!formData.region.trim()) newErrors.region = "Region is required";
          if (!formData.latitude || !formData.longitude) newErrors.location = "Please pin your location on the map";
        
          setErrors(newErrors);
          return Object.keys(newErrors).length === 0;
        };

        const handleSave = async () => {
          if (!validateForm()) return;
          const contactData = {
            contact_id: contact?.contact_id || null,
            label: formData.label,
            contact_name: formData.contactName,
            contact_phone: formData.contactNumber,
            contact_email_address: formData.contactEmail,
            is_default_address: formData.defaultAddress,
            delivery_instructions: formData.delInstructions,
            address_info: {
                latitude: formData.latitude,
                longitude: formData.longitude,
                street_address_1: formData.streetAddress1,
                street_address_2: formData.streetAddress2,
                state_province: formData.province,
                town_city: formData.city,
                zip_code: formData.zipCode,
                country: formData.country,
                landmark: formData.landmark,
                region: formData.region,
            },
        };
          const token = getTokenOrDefault();

          try {
            const response = await tryFetch({
              url: "/g/address",
              method: "POST",
              payload: contactData,
              token,
            });
      
            if (response.is_success) {
              const updatedContact = response.data as ContactInfo;
              onSave(updatedContact);
              onClose();
            } else {
              console.error("Failed to save contact:", response.errors);
              alert("Failed to save contact. Please try again.");
            }
          } catch (error) {
            console.error("Error while saving contact:", error);
            alert("An error occurred. Please try again.");
        }
        };

  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2 className="modal-header">Contact Information</h2>
        <p className="modal-text"> Use Google Chrome for seamless expereience.</p>
        <div className="modal-body">
        <Stack spacing={1}>
        <Input
          type="text"
          placeholder="Label (e.g., Home, Office, etc.)"
          name="label"
          value={formData.label}
          onChange={handleInputChange}
          variant="outlined" 
          required
          slotProps={{ input: { maxLength: 50 } }}
          error={!!errors.label}
        />
        {errors.label && <Typography color="danger">{errors.label}</Typography>}
        <Input
          type="text"
          placeholder="Name"
          name="contactName"
          value={formData.contactName}
          onChange={handleInputChange}
          variant="outlined"
          required 
          slotProps={{ input: { maxLength: 50 } }}
          error={!!errors.contactName} 
        />
        {errors.contactName && <Typography color="danger">{errors.contactName}</Typography>}
        <Input
          type="text"
          placeholder="Contact Number"
          name="contactNumber"
          value={formData.contactNumber}
          onChange={handleInputChange}
          required
          slotProps={{ input: {component: TPhoneMaskAdapter}}}
          startDecorator={<Typography>+63</Typography>}
          variant="outlined" 
        />
        {errors.contactNumber && <Typography color="danger">{errors.contactNumber}</Typography>}
        <Input
           type="email"
           placeholder="Email"
           name="contactEmail"
           value={formData.contactEmail}
           onChange={handleInputChange}
           variant="outlined"
           required 
           slotProps={{ input: { maxLength: 50 } }} 
        />
        {errors.contactEmail && <Typography color="danger">{errors.contactEmail}</Typography>}
        <Input
           placeholder="Delivery Instructions"
           name="delInstructions"
           value={formData.delInstructions}
           onChange={handleInputChange}
           variant="outlined" 
           slotProps={{ input: { maxLength: 50 } }} 
        />
        {errors.delInstructions && <Typography color="danger">{errors.delInstructions }</Typography>}
        </Stack>
        <label className="default-checkbox">
            <input
              type="checkbox"
              name="defaultAddress"
              checked={formData.defaultAddress}
              onChange={(e) =>
                setFormData((prevData) => ({
                  ...prevData,
                  defaultAddress: e.target.checked,
                }))
              }
            />
            Use as default address
          </label>
          <Typography level="body-sm" sx={{ marginTop: 1 }}>
          Please pin your location.
          </Typography>
        <div className="search-bar">
          <DragableMap onLocationChange={handleLocationChange} />
          {errors.location && <Typography color="danger">{errors.location}</Typography>}
        </div>
        <Stack spacing={1}>
        <Input
          type="text"
          placeholder="Street Address 1"
          name="streetAddress1"
          value={formData.streetAddress1}
          onChange={handleInputChange}
          variant="outlined" 
          required
          disabled={!formData.latitude || !formData.longitude}
          slotProps={{ input: { maxLength: 50 } }}
        />
        {errors.streetAddress1 && <Typography color="danger">{errors.streetAddress1}</Typography>}
        <Input
          type="text"
          placeholder="Street Address 2"
          name="streetAddress2"
          value={formData.streetAddress2}
          onChange={handleInputChange}
          variant="outlined" 
          required
          disabled={!formData.latitude || !formData.longitude}
          slotProps={{ input: { maxLength: 50 } }}
        />
        {errors.streetAddress2 && <Typography color="danger">{errors.streetAddress2}</Typography>}
        <Input type="text" placeholder="City" name="city" value={formData.city} onChange={handleInputChange}
        variant="outlined" required   />
        {errors.city && <Typography color="danger">{errors.city}</Typography>}
        <Input
          type="text"
          placeholder="Province"
          name="province"
          value={formData.province}
          onChange={handleInputChange}
          variant="outlined"
          required 
          disabled={!formData.latitude || !formData.longitude} 
          slotProps={{ input: { maxLength: 50 } }}
        />
        {errors.province && <Typography color="danger">{errors.province}</Typography>}
        <Input
          type="text"
          placeholder="Zip Code"
          name="zipCode"
          value={formData.zipCode}
          onChange={handleInputChange}
          variant="outlined" 
          required
          disabled={!formData.latitude || !formData.longitude}
          slotProps={{ input: { maxLength: 50 } }}
        />
        {errors.zipCode && <Typography color="danger">{errors.zipCode}</Typography>}
        <Stack spacing={1}>
                        <Select
                            placeholder="Select Region"
                            value={formData.region}
                            onChange={handleRegionChange}
                        >
                            {regionOptions.map((region) => (
                                <Option key={region.value} value={region.value}>
                                    {region.label}
                                </Option>
                            ))}
                        </Select>
                    </Stack>
                    {errors.region && <Typography color="danger">{errors.region}</Typography>}
        <Input
          type="text"
          placeholder="Country"
          name="country"
          value={formData.country}
          disabled
          variant="outlined"  
          required
        />
        <Input
          type="text"
          placeholder="Enter other Details (e.g. Landmark)"
          name="landmark"
          value={formData.landmark}
          onChange={handleInputChange}
          variant="outlined" 
        />
        </Stack>
         </div>
         <div className="modal-footer">
        <Button className="modal-save-button" onClick={handleSave}>
          Save
        </Button>
        <Button className="modal-close-button" onClick={onClose}>
          Cancel
        </Button>
        </div>
      </div>
    </div>
  );
};

export default AddContactModal;
