import React from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "../../styles/FgPass.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { useFgPass } from "./hooks/useFgPass"; 
import { getEnv } from "../../Helpers/Env";

const FgPass: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar(); 
  const siteKey = getEnv("GOOGLE_RECAPTCHA") as string;

  const {
    initialValues,
    validationSchema,
    handleSubmit,
    recaptcha,
    isSubmitting,
    errorMessage,
    timer,
    buttonDisabled,
  } = useFgPass(enqueueSnackbar); 

  return (
    <div className="fgpass-container">
      <div className="fgpass-box">
        <img src="https://gottit-media.b-cdn.net/s/GOTTIT-LOGO.PNG" alt="Gottit Logo" className="fgpass-logo" />
        <h1 className="fgpass-title">Forgot your password</h1>
        <p className="fgpass-subtitle">
          Please enter the email address you'd like your password reset information sent to.
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            await handleSubmit(values);
            resetForm();
          }}
        >
          {() => (
            <Form noValidate autoComplete="off" className="fgpass-form">
              <label htmlFor="email_address" className="fgpass-label">
                Enter email address
              </label>
              <Field
                type="email"
                name="email_address"
                placeholder="youremail@example.com"
                className="fgpass-input"
                required
              />
              <ErrorMessage name="email_address" component="div" className="fgpass-error-message" />
              {errorMessage && <div className="fgpass-error-message">{errorMessage}</div>}
              <button
                type="submit"
                className="fgpass-button"
                disabled={isSubmitting || buttonDisabled}
              >
                {buttonDisabled ? `Try again in ${timer}s` : "Request reset link"}
              </button>
              <ReCAPTCHA ref={recaptcha} sitekey={siteKey} size="invisible" />
            </Form>
          )}
        </Formik>
        <a href="/log-in" className="fgpass-back">
          Back To Login
        </a>
      </div>
    </div>
  );
};

const App: React.FC = () => (
  <SnackbarProvider maxSnack={3}>
    <FgPass />
  </SnackbarProvider>
);

export default App;
