import React, { createContext, useState, useEffect, ReactNode } from 'react';
// import { tryFetch } from 'Helpers/FetchAPI';
// import { getTokenOrDefault } from 'Helpers/Storage';

interface Merchant {
  id: string;
  merchant_name: string;
  category: string;
  description: string;
  hero_url: string;
  logo_url?: string;
  status?: string;
  featured?: { title: string; mime_type: string; url: string; source: string }[] | null; 
}

interface FollowingMerchantContextProps {
  followedMerchants: Merchant[];
  toggleFollow: (merchant: Merchant) => void;
  // toggleFollow: (merchant: Merchant, userId?: string) => void;
}

export const FollowingMerchantContext = createContext<FollowingMerchantContextProps | null>(null);

export const FollowingMerchantProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [followedMerchants, setFollowedMerchants] = useState<Merchant[]>([]);

//   useEffect(() => {
    
//     const fetchFollowedMerchants = async () => {
//       const token = getTokenOrDefault();
//       const response = await tryFetch({ url: '/g/follow', method: 'GET', token });
//       if (response.is_success && Array.isArray(response.data)) {
//         setFollowedMerchants(response.data as Merchant[]);
//       }
//     };

//     fetchFollowedMerchants();
//   }, []);

//   const toggleFollow = async (merchant: Merchant) => {
//     const token = getTokenOrDefault();
//     const isFollowing = followedMerchants.some((m) => m.id === merchant.id);
    
//     if (isFollowing) {
//       const response = await tryFetch({ url: `/g/unfollow/${merchant.id}`, method: 'DELETE', token });
//       if (response.is_success) {
//         setFollowedMerchants((prev) => prev.filter((m) => m.id !== merchant.id));
//       }
//     } else {

//       const response = await tryFetch({
//         url: '/g/follow',
//         method: 'POST',
//         token,
//         payload: { store_id: merchant.id },
//       });
//       if (response.is_success) {
//         setFollowedMerchants((prev) => [...prev, merchant]);
//       }
//     }
//   };

//   return (
//     <FollowingMerchantContext.Provider value={{ followedMerchants, toggleFollow }}>
//       {children}
//     </FollowingMerchantContext.Provider>
//   );
// };

  const toggleFollow = (merchant: Merchant) => {
    setFollowedMerchants((prev) => {
      const isFollowing = prev.some((m) => m.id === merchant.id);
      return isFollowing ? prev.filter((m) => m.id !== merchant.id) : [...prev, merchant];
    });
  };

  return (
    <FollowingMerchantContext.Provider value={{ followedMerchants, toggleFollow }}>
      {children}
    </FollowingMerchantContext.Provider>
  );
};
