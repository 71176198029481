import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { tryFetch } from "Helpers/FetchAPI";
import "../../styles/searchPage.scss"

const SearchPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const searchTerm = searchParams.get("query") || "";
  const [results, setResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (!searchTerm) return;
      
      setLoading(true);
      setError(null);
      try {
        const response = await tryFetch({
          url: "/search",
          method: "GET",
          query: `?searchTerm=${encodeURIComponent(searchTerm)}&page=1&limit=10`,
        });

        if (response.is_success && response.data) {
          setResults(Array.isArray(response.data) ? response.data : []);
        } else {
          setResults([]);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
        setError("Failed to load search results. Please try again.");
        setResults([]);
      } finally {
        setLoading(false);
      }
    };

    fetchSearchResults();
  }, [searchTerm]);

  const isProduct = (item: any) => item.product_name && item.product_image;
  const isMerchant = (item: any) => item.merchant_name && item.logo_url;

  return (
    <div className="search-page">
      <h2 className="search-header">Search Results for "{searchTerm}"</h2>
      {loading && <p className="loading-text">Loading...</p>}
      {error && <p className="error-text">{error}</p>}
      {!loading && !error && results.length === 0 && (
        <p className="no-results">No results found.</p>
      )}

      <div className="results-grid">
        {results.map((item) => {
          const isProduct = !!item.product_name;
          const imageUrl = isProduct
            ? item.media?.[0]?.media_url 
            : null; 

          return (
            <a
              key={item.id}
              className={`result-card ${isProduct ? "product-card" : "merchant-card"}`}
              href={isProduct ? `/merchant/${item.merchant_slug}/product/${item.product_slug}` : `/merchant/${item.merchant_slug}`}
            >
              <div className="card-content">
                {imageUrl ? (
                  <img src={imageUrl} alt={isProduct ? item.product_name : "Merchant"} />
                ) : (
                  <div className="placeholder">No Image</div>
                )}
                <h3>{isProduct ? item.product_name : "Merchant"}</h3>
                {isProduct && <p>₱{item.unit_price}</p>}
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SearchPage;
