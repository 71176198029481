import React from 'react';
import '../../../styles/mpStory.scss';

interface MpStoryProps {
  description?: string;
  ourStory?: string;
  ourProducts?: string;
  termsAndPolicies?: string;
}

export default function MpStory({ description, ourStory, ourProducts, termsAndPolicies }: MpStoryProps) {
  return (
    <div className="mp-story">
      {description && (
        <div className="mp-story-section">
          <h2>Store Description</h2>
          <p>{description}</p>
        </div>
      )}

      {ourStory && (
        <div className="mp-story-section">
          <h2>Our Story</h2>
          <p>{ourStory}</p>
        </div>
      )}

      {ourProducts && (
        <div className="mp-story-section">
          <h2>Our Products</h2>
          <p>{ourProducts}</p>
        </div>
      )}

      {termsAndPolicies && (
        <div className="mp-story-section">
          <h2>Business Information</h2>
          <p>{termsAndPolicies}</p>
        </div>
      )}
    </div>
  );
}
