import { useState } from 'react';

export default function useShare() {
  const [showShareModal, setShowShareModal] = useState(false);
  const shareUrl = window.location.href;
  const [copySuccess, setCopySuccess] = useState('');
  const defaultMessage = `Check out this merchant! Buy their products. Love it? Gottit!\n\nHere's the link: ${shareUrl}`;

  const openModal = () => setShowShareModal(true);
  const closeModal = () => setShowShareModal(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopySuccess('Link copied to clipboard!');
      setTimeout(() => setCopySuccess(''), 3000);
    } catch (err) {
      console.error('Failed to copy:', err);
      setCopySuccess('Failed to copy link.');
    }
  };

  const shareToFacebook = () => {
    const facebookPostUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
    window.open(facebookPostUrl, '_blank');
  };

  const shareToMessenger = () => {
    const messengerUrl = `https://www.messenger.com/new?link=${encodeURIComponent(shareUrl)}&message=${encodeURIComponent(defaultMessage)}`;
    window.open(messengerUrl, '_blank');
  };

  const shareToWhatsApp = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(defaultMessage)}`;
    window.open(whatsappUrl, '_blank');

  };

  const shareToEmail = (message: string) => {
    const url = `mailto:?subject=Check out this Merchant!&body=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return { showShareModal, openModal, closeModal, shareToFacebook, shareToMessenger, shareToWhatsApp, shareToEmail, copyToClipboard, defaultMessage, copySuccess };
}
