import React from 'react';
import '../../../styles/mpGallery.scss';

export default function MpGallery() {
  return (
    <div className="mp-gallery">
      <p>This Feature will be available soon.</p>
    </div>
  );
}
