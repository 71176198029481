import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import '../../../styles/Cart.scss';
import { useNavigate, Link } from 'react-router-dom';
import { tryFetch } from 'Helpers/FetchAPI';
import { getTokenOrDefault } from 'Helpers/Storage';
import PageLoading from 'Pages/layout/PageLoading';

interface CartItem {
  cart_item_id: string;
  media: { media_url: string }[];
  item_id: string;
  product_name: string;
  variation_name: string | null;
  unit_price: string;
  quantity: number;
  total_amount: string;
  stock_count: number;
  product_slug: string;
}

interface Merchant {
  store_id: string;
  store_name: string;
  store_logo: string;
  merchant_slug: string;
  items: CartItem[];
}

const CartPage: React.FC = () => {
  const navigate = useNavigate();
  const [cartData, setCartData] = useState<Merchant[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());

  const currency = process.env.REACT_APP_CURRENCY;

  const fetchCart = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = getTokenOrDefault();
      const response = await tryFetch({
        url: '/g/cart',
        method: 'GET',
        token,
      });

      if (response.is_success) {
        const merchants = response.data as Merchant[];
        setCartData(merchants);
        const initialSelected = new Set<string>();
        merchants.forEach((merchant) => {
          merchant.items.forEach((item) => initialSelected.add(item.cart_item_id));
        });
        setSelectedItems(initialSelected);
      } else {
        setError('Failed to fetch cart data.');
      }
    } catch (err) {
      console.error('Error fetching cart:', err);
      setError('An error occurred while fetching the cart.');
    } finally {
      setLoading(false);
    }
  };

  const updateCartItem = async (
    storeId: string,
    itemId: string,
    sourceKey: number,
    newQuantity: number
  ) => {
    if (newQuantity < 1) return;

    try {
      const token = getTokenOrDefault();
      const response = await tryFetch({
        url: '/g/cart',
        method: 'POST',
        token,
        payload: {
          store_id: storeId,
          item_id: itemId,
          source_key: 0,
          quantity: newQuantity,
        },
      });

      if (response.is_success) {
        fetchCart();
      } else {
        console.error('Failed to update cart item:', response.errors);
      }
    } catch (err) {
      console.error('Error updating cart item:', err);
    }
  };


  const removeCartItem = async (cartItemId: string) => {
    try {
      const token = getTokenOrDefault();
      const response = await tryFetch({
        url: `/g/cart`,
        method: 'DELETE',
        token,
        payload: { cart_item_id: [cartItemId] },
      });

      if (response.is_success) {
        fetchCart();
      } else {
        console.error('Failed to delete cart item:', response.errors);
      }
    } catch (err) {
      console.error('Error deleting cart item:', err);
    }
  };

  const handleMerchantCheckbox = (storeId: string, isChecked: boolean) => {
    setSelectedItems((prevSelected) => {
      const updatedSelected = new Set(prevSelected);
      const merchantItems = cartData
        .find((merchant) => merchant.store_id === storeId)
        ?.items.map((item) => item.cart_item_id);

      if (merchantItems) {
        if (isChecked) {
          merchantItems.forEach((id) => updatedSelected.add(id));
        } else {
          merchantItems.forEach((id) => updatedSelected.delete(id));
        }
      }
      return updatedSelected;
    });
  };

  const handleItemCheckbox = (itemId: string, isChecked: boolean, storeId: string) => {
    setSelectedItems((prevSelected) => {
      const updatedSelected = new Set(prevSelected);

      if (isChecked) {
        updatedSelected.add(itemId);
      } else {
        updatedSelected.delete(itemId);

        const merchantItems = cartData
          .find((merchant) => merchant.store_id === storeId)
          ?.items.map((item) => item.cart_item_id);

        const isMerchantUnchecked = merchantItems?.every(
          (id) => !updatedSelected.has(id)
        );

        if (isMerchantUnchecked) {
          merchantItems?.forEach((id) => updatedSelected.delete(id));
        }
      }

      return updatedSelected;
    });
  };

  const isMerchantChecked = (storeId: string) => {
    const merchantItems = cartData.find((merchant) => merchant.store_id === storeId)?.items;
    return merchantItems?.every((item) => selectedItems.has(item.cart_item_id)) || false;
  };

  const isItemChecked = (itemId: string) => selectedItems.has(itemId);

  const calculateTotal = () => {
    return cartData
      .reduce((total, merchant) => {
        return (
          total +
          merchant.items.reduce((sum, item) => {
            if (selectedItems.has(item.cart_item_id)) {
              return sum + parseFloat(item.total_amount);
            }
            return sum;
          }, 0)
        );
      }, 0)
      .toFixed(2);
  };

  useEffect(() => {
    fetchCart();
  }, []);

  if (loading) return <PageLoading />;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="cart-page">
      <h1 className="cart-header">Your Cart</h1>
      <hr className="cart-divider" />
      {cartData.length === 0 ? (
        <p>Your cart is empty.</p>
      ) : (
        cartData.map((merchant) => (
          <div key={merchant.store_id} className="merchant-section">
            <div className="merchant-header">
              <input
                type="checkbox"
                className="select-merchant"
                checked={isMerchantChecked(merchant.store_id)}
                onChange={(e) => handleMerchantCheckbox(merchant.store_id, e.target.checked)}
              />
              {merchant.store_logo && (
                <img
                  src={merchant.store_logo}
                  alt={merchant.store_name}
                  className="merchant-logo"
                />
              )}
              {/* <h2 className="merchant-name">{merchant.store_name}</h2> */}
              {/* <Link to={`/cart`} className="merchant-name"> */}
              <Link to={`/merchant/${merchant.merchant_slug}`} className="merchant-name">
                {merchant.store_name}
              </Link>
            </div>
            {merchant.items.map((item) => (
              <div key={item.cart_item_id} className="cart-item">
                <input
                  type="checkbox"
                  className="select-item"
                  checked={isItemChecked(item.cart_item_id)}
                  onChange={(e) =>
                    handleItemCheckbox(item.cart_item_id, e.target.checked, merchant.store_id)
                  }
                />
                <IoClose
                  className="remove-icon"
                  onClick={() => removeCartItem(item.cart_item_id)}
                />
                {item.media?.[0]?.media_url ? (
                  <img
                    src={item.media[0].media_url}
                    alt={item.product_name}
                    className="product-image"
                  />
                ) : (
                  <div className="product-placeholder">No Image</div>
                )}
                <div className="cart-details">
                  <h3 className="cart-product-name">{item.product_name}</h3>
                  {/* <Link
                    to={`/merchant/${merchant.merchant_slug}/product/${item.product_slug}`}
                    className="cart-product-name"
                  >
                    {item.product_name}
                  </Link> */}
                  <p className="cart-product-price">{currency} {item.unit_price}</p>
                  <p className="cart-product-variant">{item.variation_name || ''}</p>
                  <p className="cart-product-subtotal">
                    Sub Total: ₱{item.total_amount}
                  </p>
                  <div className="quantity-controls">
                    <button
                      onClick={() =>
                        updateCartItem(merchant.store_id, item.item_id, 1, item.quantity - 1)
                      }
                    >
                      -
                    </button>
                    <span>{item.quantity}</span>
                    <button
                      onClick={() =>
                        updateCartItem(merchant.store_id, item.item_id, 1, item.quantity + 1)
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            ))}
            <hr className="merchant-divider" />
          </div>
        ))
      )}

      {cartData.length > 0 && (
        <div className="cart-footer">
          <div className="total-card">
            <p className="total-amount">
              <span className="total-text">Total: </span>₱{calculateTotal()}
            </p>
            <button
          className="checkout-button"
          onClick={() =>
          navigate('/check-out', {
          state: {
          selectedItems: cartData.flatMap((merchant) =>
          merchant.items
            .filter((item) => selectedItems.has(item.cart_item_id))
            .map((item) => ({
              ...item,
              store_id: merchant.store_id,
            }))
        ),
        totalAmount: calculateTotal(),
      },
    })
  }
  disabled={selectedItems.size === 0}
>
  PROCEED TO CHECK OUT
</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartPage;
