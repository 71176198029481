import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../../styles/Home.scss';

interface Banner {
  id: number;
  type: string;
  url: string;
}

const BannerSection: React.FC = () => {
  const [carouselItems, setCarouselItems] = useState<Banner[]>([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await fetch('/assets/banner.json');
        const data = await response.json();
        setCarouselItems(data);
      } catch (error) {
        console.error('Error fetching banner data:', error);
      }
    };

    fetchBanners();
  }, []);

  return (
    <div className="banner">
      <div className="carousel">
        <Slider {...settings}>
          {carouselItems.map((banner) => (
            <div key={banner.id} className="carousel-item">
              {banner.id === 5 ? (
                <a
                  href="https://gottit.io/merchant/athenas-garden"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={banner.url} alt={`Banner ${banner.id}`} className="carousel-image" />
                </a>
              ) : (
                <img src={banner.url} alt={`Banner ${banner.id}`} className="carousel-image" />
              )}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default BannerSection;