import React, { createContext, useContext, useState, useEffect } from 'react'

export type UserType = {
    id: string,
    email_address: string,
    phone_number: string|null,
    first_name: string,
    last_name: string,
    image_url: string
}

export type SessionType = {
    isAuthenticated: boolean,
    user: UserType | null
    // access_token: string | null; //
    // refresh_token: string | null; //
}


interface IUserSessionType {
    session: SessionType,
    setSession: Function
    cartCount: number,
    setCartCount: React.Dispatch<React.SetStateAction<number>>;
    favCount: number,
    setFavCount: (count: number) => void,
}

export const SessionContext = createContext<IUserSessionType>({
    session: {
        isAuthenticated: false,
        user: null,
        // access_token: null, //
        // refresh_token: null //
    },
    setSession: () => {},
    cartCount: 0,
    setCartCount: () => {},
    favCount: 0,
    setFavCount: () => {},
});

// export default function SessionContextProvider({ children }: { children: React.ReactNode }) {
//     const storedSession = localStorage.getItem('session');
//     const initialSession: SessionType = storedSession
//         ? JSON.parse(storedSession)
//         : { isAuthenticated: false, user: null, access_token: null, refresh_token: null };

//     const [session, setSession] = useState<SessionType>(initialSession);
//     const [cartCount, setCartCount] = useState(0);
//     const [favCount, setFavCount] = useState(0);
    
//     useEffect(() => {
//         localStorage.setItem('session', JSON.stringify(session));
//     }, [session]);

//     return (
//         <SessionContext.Provider
//             value={{
//                 session,
//                 setSession,
//                 cartCount,
//                 setCartCount,
//                 favCount,
//                 setFavCount,
//             }}
//         >
//             {children}
//         </SessionContext.Provider>
//     );
// }

// export const useSessionContext = () => {
//     const context = useContext(SessionContext);
//     if (!context) {
//         throw new Error('useSessionContext must be used within a SessionContextProvider');
//     }
//     return context;
// };

export default function SessionContextProvider(props: any) {
    const { value, children } = props;
    const { session, setSession } = value;
    const [cartCount, setCartCount] = useState(0);
    const [favCount, setFavCount] = useState(0);

    return (
        <SessionContext.Provider
        value={{
            session,
            setSession,
            cartCount,
            setCartCount,
            favCount,
            setFavCount,
        }}
    >
            {children}
        </SessionContext.Provider>
    );
}

export const useSessionContext = () => {
    const context = useContext(SessionContext);
    if (!context) {
        throw new Error(
            "useSessionContext must be unsed within a SessionContextProvider"
        );
    };
    return context;
}

