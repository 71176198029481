import Footer from 'Components/Footer/Footer'
import Navbar from 'Components/Navbar/Navbar'
import { useSessionContext } from 'Context/SessionContext'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import PageLoading from './PageLoading'

const PrivateLayout = () => {
    const { session } = useSessionContext();

    if (session === null) {
        return <PageLoading/>; 
    }

    return session.isAuthenticated ? (
        <React.Fragment>
            <Navbar />
            <div className="content">
            <Outlet />
            </div>
            <Footer />
        </React.Fragment>
    ) : <Navigate to="/log-in" />;
}

export const ProtectedRoute = (props: any) => {
    const { session } = useSessionContext();
    const { children } = props;

    return session.isAuthenticated ? 
        <React.Fragment>
            <Navbar />
            <div className="content">
            {children}
            </div>
            <Footer />
        </React.Fragment> : 
        <Navigate to="/" />
}

export default PrivateLayout