import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FavoritesContext } from '../../../Context/favoritesContext';
import { FaHeart } from 'react-icons/fa';
import '../../../styles/Home.scss';
import { tryFetch } from '../../../Helpers/FetchAPI';
import { getTokenOrDefault } from 'Helpers/Storage';

const FavoritesSection: React.FC = () => {
  const favoritesContext = useContext(FavoritesContext);
  const [likedItems, setLikedItems] = useState<any[]>([]);
  const [loadedImages, setLoadedImages] = useState<{ [key: string]: boolean }>({});

  const currency = process.env.REACT_APP_CURRENCY;

  useEffect(() => {
    const fetchLikedProducts = async () => {
      try {
        const token = getTokenOrDefault();
        const response = await tryFetch({
          url: '/g/wishlist',
          method: 'GET',
          token,
        });

        if (response.is_success && Array.isArray(response.data)) {
          setLikedItems(response.data); 
        } else {
          console.error('Failed to fetch liked products:', response.errors);
        }
      } catch (error) {
        console.error('Error fetching liked products:', error);
      }
    };

    fetchLikedProducts();
  }, [favoritesContext?.likedProducts]);

  const handleImageLoad = (id: string) => {
    setLoadedImages((prev) => ({ ...prev, [id]: true }));
  };

  const handleRemoveItem = async (itemId: string) => {
    try {
      const token = getTokenOrDefault();
      const response = await tryFetch({
        url: '/g/wishlist',
        method: 'DELETE',
        token,
        payload: {item_id: itemId },
      });

      if (response.is_success) {
        setLikedItems((prevItems) =>
          prevItems.filter((item) => item.item_id !== itemId)
        );
        if (favoritesContext?.deleteFromWishlist) {
          favoritesContext.deleteFromWishlist(itemId);
        }
      } else {
        console.error('Failed to remove item from wishlist:', response.errors);
      }
    } catch (error) {
      console.error('Error removing item from wishlist:', error);
    }
  };

  const truncateText = (text: string, maxLength: number): string =>
    text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

  return (
    <div className="favorites">
      <h2>Your Wishlist</h2>
      <div className="favorites-list">
        {likedItems.length > 0 ? (
          likedItems.map((product) => (
            <div key={product.product_id} className="favorite-item">
              <Link to={`/favorites`}>
              {/* <Link to={`/merchant/${product.store_id}/product/${product.item_id}`}> */}
                <img
                  src={product.media[0]?.media_url}
                  alt={product.product_name}
                  className={`favorite-image ${
                    loadedImages[`favorite-${product.item_id}`] ? 'loaded' : ''
                  }`}
                  onLoad={() => handleImageLoad(`favorite-${product.item_id}`)}
                />
                <p className="favorite-name">{truncateText(product.product_name, 12)}</p>
                <p className="favorite-price">{currency} {parseFloat(product.unit_price).toFixed(2)}</p>
              </Link>
              <button
                className="heart-icon"
                onClick={() => handleRemoveItem(product.item_id)}
              >
                <FaHeart color="#832b99" size="22" />
              </button>
            </div>
          ))
        ) : (
          <p>Create your wishlist – Love it and Gottit!</p>
        )}
      </div>
    </div>
  );
};

export default FavoritesSection;
